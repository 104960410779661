@import "../base/mixins.scss";
@import "../base/variables.scss";

.video-container { 
  height: 0; 
  max-width: 100%; 
  overflow: hidden; 
  padding-bottom: 56.25%; 
  position: relative; 
  margin-bottom:1rem;

  a {
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    color:white;
    font-size: 2em;
    text-align:center;
    line-height: 1.6em;
    background-color:rgba(0,0,0,0.1);
    transition: all 300ms ease;
    span {
      visibility: hidden;
    }    
    &:before {
      content: "\f04b";
      font-family: FontAwesome;   
      box-sizing: content-box;
      box-shadow: 0 0 3px rgba(0,0,0,0.5);
      height: 1.6em;
      width:1.6em;
      @include ctr(both);
      z-index: 100;
      border-radius:50%;
      padding-left:4px;
      border:2px solid white;
      transition:all 300ms ease;
    }
    &:hover {
      background-color:rgba(0,0,0,0);
      &:before {
        box-shadow: 0 0 3px rgba(0,0,0,0);
        border-color:rgba(255,255,255,0.2);
      }
    }
  }



  iframe, 
  object, 
  embed,
  img { 
    height: 100%; 
    left: 0; 
    position: absolute; 
    top: 0; 
    width: 100%; 
  }
}

.video-details {
  margin-top:1rem;
  h3 {
    margin-top:0;
  }
}


