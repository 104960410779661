@import "../base/mixins.scss";
@import "../base/variables.scss";

//=========================================
// COLORS 
//=========================================

@import "../base/variables.scss";

// choa text colors
@each $value, $color in $choa-colors {
    .text-color-#{$value},
    .color-#{$value},
    .text-#{$value},
    .choa-#{$value} {
        color: $color;
    }
}

*:not(.nav li a) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.richtext-content {
    .result-container & {
        word-break:break-word;
        font-family: $gotham;
        padding-bottom:1.5rem;
        br + br {
            display:none;
        }
        ul {
            margin:0;
            padding:0;
            li {
                list-style-type: none;
                color:$text-color;
                padding-left:1rem;
                position: relative;
                &:before {
                    content:'■';
                    color:$choa-green;
                    font-size:14px;
                    position: absolute;
                    left:0;
                    top:0;
                }
            }
        }
    }
}

