
@import "../base/mixins.scss";
@import "../base/variables.scss";

.nav-tabs.search {
    li {
        margin:0;
        width:25%;
        @media screen and (min-width:$bp-sm){
            width:auto;
        }
        &:hover {
            a,i {
                color:black;
            }
            a {
                border:1px solid transparent;
            }
        }       
        a {
            padding:0.25rem 0.5rem !important;
            text-align:center;
            background-color:transparent;
            border:1px solid transparent;   
            margin-bottom:-2px;
            @media screen and (min-width:$bp-sm){
                padding:0.5rem 1rem !important;
                font-size:1rem;
            }                     
            i {
                display: block;
                font-size:1.5rem;
                padding:0.25rem;
                @media screen and (min-width:$bp-sm){
                    display:inline;
                    font-size:1rem;
                }
            }
        }
        &.active {
            a {
                border:none;
                color:$choa-green;
                border-bottom:8px solid $choa-green;
            }
            i {
                color:$choa-green;
            }
        }
    }
}