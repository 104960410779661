
@import "../base/mixins.scss";
@import "../base/variables.scss";

.tooltip {
    background-color: transparent; 
    width:auto !important;
    opacity:1 !important;
    .tooltip-inner { 
        padding: 0.5rem;   
        background-color:#fff;
        border-radius:$br-md;
        box-shadow: $shadow-sm;
        border:1px solid $black25;      
        z-index: $z-index-tooltip;      
        max-width: unset;
        width:auto;
        min-width: 80vw;
        @include tablet {
            min-width: 50vw !important;
        }
        @include desktop {
            min-width: 30vw;
        }
    }
    .tooltip-content {
        text-align:left;
        padding-left:80px;
        min-height: 70px;
    }
    .tooltip-arrow {
        top:0;
    }
    .network-badge {
        position:absolute;
        width:60px !important;
        height:60px !important;
        left:1rem;
        top:1rem;
    }
    .tooltip-content-title {
        font-weight: bold;
        font-size: 1.125rem;
        font-family: $gotham;
    }
    .tooltip-content-description {
        font-size:0.865rem;
    }

}