
@import "../base/mixins.scss";
@import "../base/variables.scss";


// remove .search-results to make this update site wide
.search-results span.pagination, 
.search-results div.pagination {
    background-color:transparent;
    border-top:1px solid $black10;
    border-bottom:1px solid $black10;
    padding:1rem 0;
	display:block;
    .pagination-next,
    .pagination-prev {
        a {
            border:1px solid $black10;
            &:hover {
                border:1px solid $black10;
            }
        }
    }
    nav {
        background-color:transparent;
    }
    li {
        a,span {
            background-color:transparent;
            cursor:pointer;
            &:hover {
                background-color:transparent; 
                color:#000;
                font-weight: 700;
            }
        }
        &.disabled:hover a {
            font-weight: 300;
            color:rgba(100,100,100,.5);
        }
        &.active a {
            background-color:$choa-green;
            color:#fff;
            font-weight: 900;
        }
    }
}
