@import "../base/mixins.scss";
@import "../base/variables.scss";


.modal-backdrop {
    background-color: transparent;
    transition:all 300ms ease;
}
.modal-backdrop.in {
    //.search & {
        background-color:rgba(150, 150, 150, 0.8);
        opacity:0.9;
        visibility: visible;
    //}
}
.modal {
    z-index:$z-index-modal;
    &.in {
        padding-right:0 !important;
    }
}
.modal-content {
    height: auto;
    opacity: 1;
}


  
.modal-lite {
    padding:2rem 0;
    overflow-y:scroll;
    .modal-content {
        border:1px solid $black10;
        border-radius: 0;
        box-shadow: $shadow-sm;
    }
    .close {
        position: fixed;
        top:1rem;
        right:2rem;

        padding: 1rem;
        width:60px;
        height:60px;
        background-color: $choa-green;
        color:white;
        font-size: 30px;
        line-height: 30px;
        opacity:1;
        transition:all 300ms ease;
        z-index: 1;
        span {
            @include ctr(both);
        }
        &:hover {
            background-color:darken($choa-green, 10%);
        }
    }
}
// Primary search modal
.modal-search {
    .modal-content {
        
        box-shadow: none;
        border:none;
        padding:0;
        padding-top:3rem;
        height:100%;
        opacity:1;
        border-radius:0;
        background-color:transparent;
        .modal-search-form {
        //@include ctr(x);
        //bottom:70vh;
        
        height: auto;
        //margin:0 auto;
        //width:100%;
        @media screen and (min-width:$bp-md){
            //bottom:60vh;
            //width:80%;
        }
        @media screen and (min-width:$bp-lg){
            //width:70%;
        }
        @media screen and (max-height:600px) and (orientation:landscape){
            //width:75%;
            //bottom:50vh;
        }
        > .input-group {
            background-color:white;
            border:1px solid $black10;
            padding:0.5rem;
        }
        }
        .searchbox-input {  
            line-height: 1em;   
            padding:0.5rem 1rem;  
            padding-right:15%;  
            @include fluid-type($min-width, $max-width, 1.3rem, $max-font); /*Declared in production as 1.5rem but it causes the text to go to the next line on mobile*/
            box-sizing: border-box;
            height: 15vw;
            max-height:80px;
            @include desktop {
            padding-right:10%; 
            height:10vw;
            max-height:100px;
            }
        }      
        .searchbox-submit-wrapper {
            &:before {
                content: "\f002";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;                
            }
        }
        .searchbox-submit {
            position: relative;  
            border-left:none;         
            z-index: 10;
            background-color: transparent;
            text-transform: uppercase;
            line-height: 1em;
            border-left:1px solid $black25; 

            width:15vw;
            max-width:80px;
            height:15vw;
            max-height:80px;
            @include link-blue;
            @include desktop {
                width:10vw;
                max-width:100px;
                height:10vw;
            }
            .fa-search {
                @include fluid-type($min-width, $max-width, 1.25rem, 2rem); 
            } 
                

        }
        .searchbox-reset {

            border: none;
            background-color: transparent;
            position: absolute;
            top:0;
            right:12vw;
            @include desktop {
                right:100px;
            }
            color: $black10;
            z-index: 10;
            height: 100%;
            width:100px;
            transition: all ease 300ms;      
            .fa-close {
                @include fluid-type($min-width, $max-width, 1rem, 1.5rem);
                &:before {
                    content: "\f00d";
                }
            }     
            &:hover {
                color: $black25;
            } 
        }
        .close {
            position: relative;
            padding: 1rem;
            width:60px;
            height:60px;
            background-color: $choa-green;
            color:white;
            font-size: 30px;
            line-height: 30px;
            opacity:1;
            transition:all 300ms ease;
            span {
                @include ctr(both);
            }
            &:hover {
            background-color:darken($choa-green, 10%);
            }
        }
    }

}
  

.location-content {
    font-size: 1rem;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:#ccc;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#ccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color:#ccc;
}
input[type=search] {
	-webkit-appearance: none; //This is to overwrite the input style coming from main.css line 147. It is declared as textfield and causes the input field to change to an oval shape.
}
