@import "../base/mixins.scss";
@import "../base/variables.scss";


.range-slider-wrapper {
    padding: 15px;
}


.toggle-filters {
    display: inline-block;
    padding: 15px 10px;
    margin-top: 15px;
    margin-left: 15px;
    cursor: pointer;
    background-color: #999;
    color: #eee;
    min-width: 162px;
    text-align: center;
    border-radius: 5px;
    transition: all .6s ease;
    &:hover {
        background-color: #932222;
        color: #fff;
    }
}

.slider-heading {
    display: block;
    font-size: 17px;
    font-style: italic;
    margin-bottom: 50px;
    margin-left: 15px;
}

.slider {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: $black25;
    margin-bottom: 25px;
    border-radius: 6px;
    .ui-slider-handle {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: $choa-green;
        border-radius: 100%;
        box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, .2);
        cursor: pointer;
        outline: none;
    }
}

.value {
    display: block;
    position: absolute;
    padding: 7px;
    background-color: $black10;
    border-radius: 5px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
    font-size: 11px;
}

.value::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(194,225,245,0);
    border-top-color: $black10;
    border-width: 5px;
    margin-left: -5px;
}

.ui-state-focus,
.ui-state-hover{
    border-color: transparent !important;
}    

.ui-slider-range-min {
    background:$choa-green;
}