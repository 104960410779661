.badge-cpg {
    background-image: url(../images/badge-CPG.png);
    background-size: cover;
}
.badge-tccn {
    background-image: url(../images/badge-TCCN.png);
    background-size: cover;
}

.facet-graphic-container {
	&[class*="location-"] {
		background-repeat:no-repeat;
		background-size: contain;
		background-position:center;
	}

	&.location-emergency {
		background-image: url(../../assets/images/map-pin-hospital-small.png);
	}
	&.location-urgent {
		background-image: url(../../assets/images/map-pin-urgent-care-small.png);
	}
	&.location-telemedicine {
		background-image: url(../../assets/images/map-pin-telemed-small.png);
	}
	&.location-neighborhood {
		background-image: url(../../assets/images/map-pin-clinic-small.png);
	}
}