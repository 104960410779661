@import "../base/mixins.scss";
@import "../base/variables.scss";



.profile-header {
    position:relative;
    display: block;
    padding-top:40px;
    @include clearfix;
    @include mobile {
        display:flex;
        .doctor-image {
            min-width:unset;
            width:auto;
        }
    }
}

.profile-summary {
    display: block;
    @media screen and (min-width:$bp-sm){
        width:30%;
        max-width: 300px;
        float:left;
    }
}

.profile-body {
    display: block;
    padding:0 1.5rem;
    @include mobile {
        padding:0 1rem;
    }
    @media screen and (min-width:$bp-sm){
        width: 70%;
        float:left;
    }
}

// profile header 

$back-button-height:40px !default;

.header-back {
    position: absolute;
    right:0;
    top:0;
    left:0;
    @media screen and (min-width:$bp-sm){
        margin:0;
        right:1.5rem;
        top:1.5rem;
        left:auto;
        z-index: 1;
    }
    
    a.back {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:$back-button-height;
        padding:0.5rem 1rem;
        font-family: $gotham;
        font-weight: bold;
        color:white;
        background-color: $choa-blue;        
        @media screen and (min-width:$bp-sm){
			position:relative;
            left:unset;
            width:auto;
            border-radius: 6px;
            background-color: transparent;
            color:$choa-blue;
            border:1px solid $choa-blue;
        }
        

        
    }    
}


.doctor-image {
    min-width: 110px;
    width:30%;
    max-width: 300px;
    float:left;
    margin:1rem 0.5rem;
    @media screen and (min-width:$bp-sm){
        padding-top:0;
        margin:0 auto;
        margin-bottom:-30px;
    }    
    .image-wrapper {
        position: relative;
        border-radius: $br-md;
        min-width:75px;
        width:15vw;
        min-height:100px;
        height:20vw;
        margin:0 auto;
        background-size: cover;
        background-position: center;
        @media screen and (min-width:$bp-sm){
            border-radius: $br-lg;
            width:160px;
            height:200px;
        }
    }    
    &.male {
        .image-wrapper {
            background-image:url('../images/profile-male.png');
        }
    }
    &.female {
        .image-wrapper {
            background-image:url('../images/profile-female.png');
        }
    }

    
}
.header-doctor {
    position: relative;
    display: block;
    display: flex;
    width:100%;
    float:left;
    @media screen and (min-width:$bp-sm){
        padding-top:0;
        display:block;
    }

}

// profile summary 


.mychart {
    display: block;
    padding: 1rem 0;
    img {
		width: auto;
    }
}

.doctor-cape {
	background-color:#999;
    margin-bottom:1.5rem;
    @media screen and (min-width:$bp-sm){
        padding-top:50px;
    }    
    .cpg & {
        background-color:$choa-green;
        .mychart {
            border-color:$choa-green;
        }
    }
    .mychart {
        background-color:white;
        text-align: center;
        padding:1rem;
        border:4px solid #999;
        margin-top:-1px;
    }
	.makeappt {
		background-color: #0081C6;
		text-align: center;
		padding: 1rem;
		border: 4px solid #00a94f;
		margin-top: -1px;
		color: #fff !important; 
		font-size: 1rem;
	}
	.calendar-icon {
		font-family: 'choaGlyps' !important;
		color: rgb(255, 255, 255);
		padding: 6px 5px 0 5px;
		font-size: 1rem;
	}
	
}
%cape-title {
    color: $white75;
    display: block;
    font-family: $gotham;    
    font-weight: 500;
    font-size: 0.75rem;
}
.cape-group {
    padding:1rem;
    &:not(:last-child){
        border-bottom:1px solid $white10;
    }
    .cape-group-title {
        @extend %cape-title;
        color:#fff;
        text-transform: uppercase;
        font-weight: 700;
    }
    .cape-group-item {
        color:#fff;
        font-size: 1.125rem;
        line-height: 1.2;
        word-break: break-word;
        a.network {
            color:#fff;
            font-size: 0.865em;
            line-height: 1;
            display:block;
            border-radius:4px;
            padding:8px;
            margin:-2px -8px;
            transition: all 300ms ease;
            &:hover {
                background-color:rgba(0,0,0,0.1);
                color:$choa-yellow;
            }
            @include clearfix();
        }
        a:not(.network) {
            color:$choa-yellow;
        }

        .group-item-icon {
            display: inline-block;
            width:50px;
            height:50px;
            float:left;
            background-color: rgba(0,0,0,0.2);
            background-size: cover;
            border-radius:$br-sm;
            overflow:hidden;
            transition: all 300ms ease;
        }
        .group-item-title {
            @extend %cape-title;
            margin-top:1rem;
        }
        .group-item-text {
            width:calc(100% - 50px);
            min-height:50px;
            float:left;
            line-height: 1;
            padding-left:0.5rem;
            position: relative;
            span {
                @include ctr(y);
            }
        }
    }
}    
.doctor-tag {
    position: relative;
    .name {
        color:$choa-green;
        font-family: $gotham;
        font-weight: bold;
        margin-bottom:0;
        font-size:1.5rem;
        @include desktop {
            font-size:2rem;
        }
                 
    }
    .specialty {
        color:$text-color-dark;
        font-family: $gotham;
        font-weight: bold;
        color:$text-color-dark;
        font-size:1.25rem;
        line-height: 1.25;
        @include desktop {
            font-size:1.5rem;
        }
    }
}

// doctor gender and language
.specs {
    font-size:1rem;
    margin-bottom:1rem;
    &.specs-table {
        display: flex;
        flex-wrap: wrap;
    }
    .spec-wrapper {
        margin-right:1rem;
    }
    .spec {
        text-transform: initial;
        font-size: 1rem;
        color: $text-color-dark;
    }
}

// profile body 

.label-container {
    bottom: 0;
    position: absolute;
    width:100%;
    height:60px;
    text-align:center;
    display:block; 
    background-image: linear-gradient(to bottom, transparent, white 50%);    

    #read-more-label {
        background-color: white;
        color:$choa-blue;
        font-family: $gotham;
        cursor: pointer;
        font-size:1rem;
        @include ctr(x);
        bottom:0;
        &:before {
            content: '- Read More -';
        }
    }
}

#read-more-trigger {
    display: none;
    &:checked {
        ~ .label-container {
            background-image: linear-gradient(to bottom, transparent, transparent 50%);    
            #read-more-label {
                &:before {
                    content: '- Read Less -';
                }
            }
            .shadow {
                padding: 0;
            }
        }
        & ~ .line-clamp {
            max-height: 1000em;
        }
    }
}

.read-more { 
    position: absolute; 
    display:block;
    font-family: $gotham;
    font-weight: bold;
    bottom: 2px; 
    left: 0;
    width: 100%; 
    text-align: center; 
    margin: 0; padding: 60px 0 0; 
    background-image: linear-gradient(to bottom, transparent, white 50%);    
    a {
        background-color:white;
        padding:0.25rem 2rem;
        position: relative;
        &:hover {
            &:before,
            &:after {
                color:$choa-blue;
            }
            &:before {
                left:-10px;
            }
            &:after {
                right:-10px;
            }                
        }
        &:before, 
        &:after {
            
            color:$black10;
            position: relative;
            @include transition(300ms ease);
        }
        &:before {
            left:-20px;
            content:" [ ";
        }
        &:after {
            right:-20px;
            content:" ] ";
        }            
    }
}

.bio {

    font-size: 1.25em;
    position: relative;
    margin-bottom:1.5rem;

    p {
        margin:0;
        padding-bottom:1rem;
    }
    .line-clamp {
        max-height: 180px;
        padding-bottom:1.5rem;
        transition:max-height 600ms ease;     
        overflow: hidden;           
    }
}


.profile-details {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    > * {
        flex: 1 100%;
    }
    .details {
        display: block;
        float: left;
        padding-bottom:1.5rem;
        font-size: 1rem;
        h2 {
            border-bottom:1px solid #333;
            font-size: 1.5rem;
            padding: 1rem 0;
            padding-top:0;
            margin-top:0;
        }
        ul {
            margin:0;
            padding:0;
            list-style-type: none;
            font-size: 1rem;
            color:$choa-green;
            li {
                position: relative;
                padding-left:20px;
                &:before {
                    content: "\f0c8";
                    font-family: FontAwesome;                    
                    color:$choa-green; 
                    font-size: 9px;
                    position: absolute;
                    left:0px;
                    top:5px;
                }
                span {
                    color:$text-color;
                }
            }
        }
        &.full {
            width:100%;
        }
        &.half {
            width:49%;
            flex: 2 49%;
            +.half {
                width:49%;
                margin-left:2%;
            }
            &:only-child {
                width:100%;
                margin:0;
            }
        }
    }  
}

.media-item {
    + .media-item {
        margin-top:1.5rem;
    }
    h3 {
        margin-top:0;
    }
    time {
        color:$text-color-dark;
    }
    cite {
        position:relative;
        color:$text-color-light;
        &:after {
            position:absolute;
            content:" ";
            top:-0.5rem;
            left:0;
            display: block;
            width: 3rem;
            height: 1px;
            background-color: #333;
        }
    }
}  
.media-description {

}


.location {
    position: relative;
    padding:0 1rem;
    font-size: 1rem;
    &:before {
        content: "\f041";
        font-family: FontAwesome;                    
        color:$text-color-light; 
        font-size: 20px;
        position: absolute;
        left:0;
        top:0;        
    }
    h3 {
        font-size: 1rem;
        margin-bottom: 0.25rem;
    }
    address {
        margin-bottom: 0;
    }
    .link {
        display: block;
    }
}

.media-wrapper {
    display: block;  
    width:100%;
    padding:0.75rem;
    border:1px solid $black10;    
    border-radius:12px;
    margin-bottom:1.5rem;
    @media screen and (min-width:$bp-md){
        float: right;
        margin-left:1rem;
    }
    &:last-child {
        width:100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
    }
    &.map {
        width:100%;
        height:100%;
        z-index: 1;
        position: relative;        
        @media screen and (min-width:$bp-md){
            width:30vw;
            max-width: 400px;
            height: 30vw;
            max-height: 400px;  
        }
		#map {
            background-color: $black10;
            border:none;
            margin:0;
            height:100%;
		}
    }
    &.video {
        width: 100%;
        .embed-responsive {
            background-color: $black10;
        }
        @media screen and (min-width:$bp-md){
            width:30vw;
            max-width: 400px;              
            
        }
    }    
}
.thumb-wrapper {
    .poster {
        z-index: 10;
    }
}

.map-link {
    text-align: center;
    button {
        background-color: transparent;
        @media screen and (min-width:$bp-md){
            display: none;
        }
        padding:0.25rem 0.5rem;
        border:$border;
        border-color:$choa-blue;
        border-radius: $br-sm;
        font-weight: bold;
        color:$choa-blue;
    }
}
.map {
    display:none;
    @media screen and (min-width:$bp-md){
        display: block;
    }    
}