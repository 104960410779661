

// External Resources
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Core Libraries
@import 'base/mixins.scss';
@import 'base/variables.scss';
@import 'base/typography.scss';

// Componenets
@import 'component/icons.scss';
@import 'component/modal.scss';
@import 'component/navigation.scss';
@import 'component/pagination.scss';
@import 'component/slider.scss';
@import 'component/tooltip.scss';
@import 'component/video.scss';

// Layouts
@import 'layout/search-results.scss';
@import 'layout/profile.scss';
@import 'layout/search.scss';



//=========================================
// OVERRIDES
//=========================================


/*.nav.navbar-nav {
    margin:auto;
}
div#navbar div.container-fluid.navbar-bg-col ul#primary-nav {
    margin-left: auto !important;
    margin-top:-15px;
    @include ctr(y);
    left:200px;
}*/


html {
    font-size: 18px;
}
body {
    font-family: $gotham;
    color:$text-color;
    overflow-x:hidden;
    &.modal-open {
        overflow:hidden;
    }
}
h1,h2,h3 {
    font-family: $gotham;
    font-weight:bold;
    color:$text-color-dark;
}
h4 {
	font-weight:bold;
}
strong {
	font-weight:bold;
}
p {
    line-height: 1.5;
    margin-bottom:1rem;
}
.sub-head,
.nav-title {
	font-weight:bold;
}
.related .relatedChild p {
	font-weight:bold;
}
a {
    &.page-link,
    &.english-spanish-link {
        font-weight: $bold;
    }
    &:hover {
        text-decoration: none;
    }
}
/*.navbar-default .navbar-nav>li>a:hover {
    color:$choa-yellow;
}*/

.page-navigation {
    font-family: $gotham;
    font-weight: $bold;
}

.filter-options {
    label {
        transition:all 300ms ease;
        p,
        &:before {
            transition:all 300ms ease;
        }
        &:hover {
            color:#000 !important;
            p,
            &:before {
                color:#000 !important;
            }
        }
    }
}

// Adding bold back where font-stack deficiency fallbacks were occuring
ul.breadcrumbs li a,
.panel-body a, p.subhead {
    font-weight: bold;
}
.facility .info .location .name {
	font-weight:bold;
}