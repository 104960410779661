@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito");
.text-color-red,
.color-red,
.text-red,
.choa-red {
  color: #ed1c24; }

.text-color-gold,
.color-gold,
.text-gold,
.choa-gold {
  color: #f6b221; }

.text-color-yellow,
.color-yellow,
.text-yellow,
.choa-yellow {
  color: #ffdd00; }

.text-color-green,
.color-green,
.text-green,
.choa-green {
  color: #00a94f; }

.text-color-lightblue,
.color-lightblue,
.text-lightblue,
.choa-lightblue {
  color: #4bbbeb; }

.text-color-blue,
.color-blue,
.text-blue,
.choa-blue {
  color: #0081c6; }

.text-color-darkblue,
.color-darkblue,
.text-darkblue,
.choa-darkblue {
  color: #005da4; }

*:not(.nav li a) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word; }

.result-container .richtext-content {
  word-break: break-word;
  font-family: GothamRounded-Book;
  padding-bottom: 1.5rem; }
  .result-container .richtext-content br + br {
    display: none; }
  .result-container .richtext-content ul {
    margin: 0;
    padding: 0; }
    .result-container .richtext-content ul li {
      list-style-type: none;
      color: #555555;
      padding-left: 1rem;
      position: relative; }
      .result-container .richtext-content ul li:before {
        content: '■';
        color: #00a94f;
        font-size: 14px;
        position: absolute;
        left: 0;
        top: 0; }

.badge-cpg {
  background-image: url(../images/badge-CPG.png);
  background-size: cover; }

.badge-tccn {
  background-image: url(../images/badge-TCCN.png);
  background-size: cover; }

.facet-graphic-container[class*="location-"] {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; }

.facet-graphic-container.location-emergency {
  background-image: url(../../assets/images/map-pin-hospital-small.png); }

.facet-graphic-container.location-urgent {
  background-image: url(../../assets/images/map-pin-urgent-care-small.png); }

.facet-graphic-container.location-telemedicine {
  background-image: url(../../assets/images/map-pin-telemed-small.png); }

.facet-graphic-container.location-neighborhood {
  background-image: url(../../assets/images/map-pin-clinic-small.png); }

.modal-backdrop {
  background-color: transparent;
  transition: all 300ms ease; }

.modal-backdrop.in {
  background-color: rgba(150, 150, 150, 0.8);
  opacity: 0.9;
  visibility: visible; }

.modal {
  z-index: 1050; }
  .modal.in {
    padding-right: 0 !important; }

.modal-content {
  height: auto;
  opacity: 1; }

.modal-lite {
  padding: 2rem 0;
  overflow-y: scroll; }
  .modal-lite .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.6); }
  .modal-lite .close {
    position: fixed;
    top: 1rem;
    right: 2rem;
    padding: 1rem;
    width: 60px;
    height: 60px;
    background-color: #00a94f;
    color: white;
    font-size: 30px;
    line-height: 30px;
    opacity: 1;
    transition: all 300ms ease;
    z-index: 1; }
    .modal-lite .close span {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .modal-lite .close:hover {
      background-color: #007637; }

.modal-search .modal-content {
  box-shadow: none;
  border: none;
  padding: 0;
  padding-top: 3rem;
  height: 100%;
  opacity: 1;
  border-radius: 0;
  background-color: transparent; }
  .modal-search .modal-content .modal-search-form {
    height: auto; }
    .modal-search .modal-content .modal-search-form > .input-group {
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.5rem; }
  .modal-search .modal-content .searchbox-input {
    line-height: 1em;
    padding: 0.5rem 1rem;
    padding-right: 15%;
    /*Declared in production as 1.5rem but it causes the text to go to the next line on mobile*/
    box-sizing: border-box;
    height: 15vw;
    max-height: 80px; }
    .modal-search .modal-content .searchbox-input {
      font-size: 1.3rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .modal-content .searchbox-input {
          font-size: calc(1.3rem + 1.7 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .modal-content .searchbox-input {
          font-size: 3rem; } }
    @media (min-width: 1024px) {
      .modal-search .modal-content .searchbox-input {
        padding-right: 10%;
        height: 10vw;
        max-height: 100px; } }
  .modal-search .modal-content .searchbox-submit-wrapper:before {
    content: "\f002";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit; }
  .modal-search .modal-content .searchbox-submit {
    position: relative;
    border-left: none;
    z-index: 10;
    background-color: transparent;
    text-transform: uppercase;
    line-height: 1em;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    width: 15vw;
    max-width: 80px;
    height: 15vw;
    max-height: 80px;
    transition: color 300ms ease;
    color: #0081c6; }
    .modal-search .modal-content .searchbox-submit:hover {
      color: #00a2f9; }
    @media (min-width: 1024px) {
      .modal-search .modal-content .searchbox-submit {
        width: 10vw;
        max-width: 100px;
        height: 10vw; } }
    .modal-search .modal-content .searchbox-submit .fa-search {
      font-size: 1.25rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .modal-content .searchbox-submit .fa-search {
          font-size: calc(1.25rem + 0.75 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .modal-content .searchbox-submit .fa-search {
          font-size: 2rem; } }
  .modal-search .modal-content .searchbox-reset {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 12vw;
    color: rgba(0, 0, 0, 0.1);
    z-index: 10;
    height: 100%;
    width: 100px;
    transition: all ease 300ms; }
    @media (min-width: 1024px) {
      .modal-search .modal-content .searchbox-reset {
        right: 100px; } }
    .modal-search .modal-content .searchbox-reset .fa-close {
      font-size: 1rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .modal-content .searchbox-reset .fa-close {
          font-size: calc(1rem + 0.5 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .modal-content .searchbox-reset .fa-close {
          font-size: 1.5rem; } }
    .modal-search .modal-content .searchbox-reset .fa-close:before {
      content: "\f00d"; }
    .modal-search .modal-content .searchbox-reset:hover {
      color: rgba(0, 0, 0, 0.25); }
  .modal-search .modal-content .close {
    position: relative;
    padding: 1rem;
    width: 60px;
    height: 60px;
    background-color: #00a94f;
    color: white;
    font-size: 30px;
    line-height: 30px;
    opacity: 1;
    transition: all 300ms ease; }
    .modal-search .modal-content .close span {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .modal-search .modal-content .close:hover {
      background-color: #007637; }

.location-content {
  font-size: 1rem; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1;
  /* Firefox */ }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc; }

input[type=search] {
  -webkit-appearance: none; }

.nav-tabs.search li {
  margin: 0;
  width: 25%; }
  @media screen and (min-width: 768px) {
    .nav-tabs.search li {
      width: auto; } }
  .nav-tabs.search li:hover a, .nav-tabs.search li:hover i {
    color: black; }
  .nav-tabs.search li:hover a {
    border: 1px solid transparent; }
  .nav-tabs.search li a {
    padding: 0.25rem 0.5rem !important;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    margin-bottom: -2px; }
    @media screen and (min-width: 768px) {
      .nav-tabs.search li a {
        padding: 0.5rem 1rem !important;
        font-size: 1rem; } }
    .nav-tabs.search li a i {
      display: block;
      font-size: 1.5rem;
      padding: 0.25rem; }
      @media screen and (min-width: 768px) {
        .nav-tabs.search li a i {
          display: inline;
          font-size: 1rem; } }
  .nav-tabs.search li.active a {
    border: none;
    color: #00a94f;
    border-bottom: 8px solid #00a94f; }
  .nav-tabs.search li.active i {
    color: #00a94f; }

.search-results span.pagination,
.search-results div.pagination {
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  display: block; }
  .search-results span.pagination .pagination-next a,
  .search-results span.pagination .pagination-prev a,
  .search-results div.pagination .pagination-next a,
  .search-results div.pagination .pagination-prev a {
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .search-results span.pagination .pagination-next a:hover,
    .search-results span.pagination .pagination-prev a:hover,
    .search-results div.pagination .pagination-next a:hover,
    .search-results div.pagination .pagination-prev a:hover {
      border: 1px solid rgba(0, 0, 0, 0.1); }
  .search-results span.pagination nav,
  .search-results div.pagination nav {
    background-color: transparent; }
  .search-results span.pagination li a, .search-results span.pagination li span,
  .search-results div.pagination li a,
  .search-results div.pagination li span {
    background-color: transparent;
    cursor: pointer; }
    .search-results span.pagination li a:hover, .search-results span.pagination li span:hover,
    .search-results div.pagination li a:hover,
    .search-results div.pagination li span:hover {
      background-color: transparent;
      color: #000;
      font-weight: 700; }
  .search-results span.pagination li.disabled:hover a,
  .search-results div.pagination li.disabled:hover a {
    font-weight: 300;
    color: rgba(100, 100, 100, 0.5); }
  .search-results span.pagination li.active a,
  .search-results div.pagination li.active a {
    background-color: #00a94f;
    color: #fff;
    font-weight: 900; }

.range-slider-wrapper {
  padding: 15px; }

.toggle-filters {
  display: inline-block;
  padding: 15px 10px;
  margin-top: 15px;
  margin-left: 15px;
  cursor: pointer;
  background-color: #999;
  color: #eee;
  min-width: 162px;
  text-align: center;
  border-radius: 5px;
  transition: all .6s ease; }
  .toggle-filters:hover {
    background-color: #932222;
    color: #fff; }

.slider-heading {
  display: block;
  font-size: 17px;
  font-style: italic;
  margin-bottom: 50px;
  margin-left: 15px; }

.slider {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;
  border-radius: 6px; }
  .slider .ui-slider-handle {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #00a94f;
    border-radius: 100%;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none; }

.value {
  display: block;
  position: absolute;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  font-size: 11px; }

.value::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: rgba(0, 0, 0, 0.1);
  border-width: 5px;
  margin-left: -5px; }

.ui-state-focus,
.ui-state-hover {
  border-color: transparent !important; }

.ui-slider-range-min {
  background: #00a94f; }

.tooltip {
  background-color: transparent;
  width: auto !important;
  opacity: 1 !important; }
  .tooltip .tooltip-inner {
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.25);
    z-index: 1020;
    max-width: unset;
    width: auto;
    min-width: 80vw; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .tooltip .tooltip-inner {
        min-width: 50vw !important; } }
    @media (min-width: 1024px) {
      .tooltip .tooltip-inner {
        min-width: 30vw; } }
  .tooltip .tooltip-content {
    text-align: left;
    padding-left: 80px;
    min-height: 70px; }
  .tooltip .tooltip-arrow {
    top: 0; }
  .tooltip .network-badge {
    position: absolute;
    width: 60px !important;
    height: 60px !important;
    left: 1rem;
    top: 1rem; }
  .tooltip .tooltip-content-title {
    font-weight: bold;
    font-size: 1.125rem;
    font-family: GothamRounded-Book; }
  .tooltip .tooltip-content-description {
    font-size: 0.865rem; }

.video-container {
  height: 0;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 1rem; }
  .video-container a {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: white;
    font-size: 2em;
    text-align: center;
    line-height: 1.6em;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 300ms ease; }
    .video-container a span {
      visibility: hidden; }
    .video-container a:before {
      content: "\f04b";
      font-family: FontAwesome;
      box-sizing: content-box;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      height: 1.6em;
      width: 1.6em;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 100;
      border-radius: 50%;
      padding-left: 4px;
      border: 2px solid white;
      transition: all 300ms ease; }
    .video-container a:hover {
      background-color: rgba(0, 0, 0, 0); }
      .video-container a:hover:before {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0);
        border-color: rgba(255, 255, 255, 0.2); }
  .video-container iframe,
  .video-container object,
  .video-container embed,
  .video-container img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.video-details {
  margin-top: 1rem; }
  .video-details h3 {
    margin-top: 0; }

.more-results {
  margin-bottom: 1rem; }
  .more-results .fa {
    font-weight: 900;
    margin-right: 10px; }

a.link span {
  border-bottom: 1px dashed transparent;
  transition: all 300ms ease; }

a.link:hover span {
  border-color: #0081c6; }

.tokens {
  display: block;
  margin-bottom: 1.5rem;
  margin-top: 1rem; }

.token {
  position: relative;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  padding-right: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  box-shadow: 1px 4px 2px -2px rgba(0, 0, 0, 0.1); }
  .token .close {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
    min-height: 1.5rem;
    color: #888888;
    transition: all 300ms ease;
    font-size: 24px; }
    .token .close:hover {
      color: #222222; }
    .token .close:active:hover {
      border-color: red; }

.filter-options .radio-container [type=radio] + span:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 1em;
  box-shadow: 0 0 0 2px #666;
  vertical-align: top;
  margin: 3px 5% 0 0; }
  @media (min-width: 992px) {
    .filter-options .radio-container [type=radio] + span:before {
      width: 10px;
      height: 10px;
      margin: 3px 5% 0 0;
      box-shadow: 0 0 0 1.5px #666; } }

.filter-see-all-toggle {
  position: relative;
  margin-top: 1rem; }
  .filter-see-all-toggle i {
    float: left; }
  .filter-see-all-toggle i, .filter-see-all-toggle p {
    color: #0081c6 !important;
    font-weight: 700; }
  .filter-see-all-toggle:hover i {
    animation: zoom 300ms ease 3; }

@keyframes zoom {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

.search-alternate {
  padding: 1rem 0;
  font-size: 1.25rem; }
  .search-alternate a {
    transition: color 300ms ease, border-color 300ms ease;
    color: #0081c6;
    border-bottom: 1px dashed transparent; }
    .search-alternate a:hover {
      color: #00a2f9;
      border-color: #0081c6; }
  .search-alternate:empty {
    padding: 0 !important; }

.result-container {
  font-size: 18px;
  width: 100%;
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  line-height: 1.5; }
  .result-container:after {
    content: "";
    display: table;
    clear: both; }
  .result-container .doctor-image {
    position: relative;
    width: 160px;
    margin-right: 1rem;
    margin-bottom: 0; }
    .result-container .doctor-image + .result-summary {
      width: calc(100% - 160px - 1rem); }
    @media (max-width: 767px) {
      .result-container .doctor-image {
        margin: 0;
        margin-top: 0.5rem;
        margin-right: 1rem;
        width: 85px;
        height: 120px;
        min-width: unset;
        max-width: unset; }
        .result-container .doctor-image .image-wrapper {
          width: 85px !important;
          height: 120px; }
        .result-container .doctor-image + .result-summary {
          width: calc(100% - 85px - 1rem); } }
  .result-container a {
    transition: color 300ms ease, border-color 300ms ease;
    color: #0081c6;
    border-bottom: 1px dashed transparent; }
    .result-container a:hover {
      color: #00a2f9;
      border-color: #0081c6; }
  .result-container:after {
    content: "";
    display: table;
    clear: both; }
  .result-container + .result-container {
    border-top: 2px solid rgba(0, 0, 0, 0.05); }

.result-container h4,
.appointment-title h4,
.spec-wrapper h4,
.specs h4 {
  font-weight: 700;
  font-size: 0.75rem;
  color: #222222;
  line-height: 1.2;
  margin-top: 1rem;
  margin-bottom: 0; }

.error-wrapper {
  display: block;
  width: 100%;
  padding: 1.5rem 1rem; }
  .error-wrapper .no-results {
    text-align: center;
    width: 100%;
    font-size: 1.25rem; }
    .error-wrapper .no-results span {
      color: #ed1c24;
      font-style: italic; }

.result-icon {
  width: 100px;
  float: left; }
  @media screen and (min-width: 992px) {
    .result-icon {
      width: 120px; } }
  .result-icon + .result-summary {
    width: calc(100% - 100px); }
    @media screen and (min-width: 992px) {
      .result-icon + .result-summary {
        width: calc(100% - 120px); } }

.result-summary {
  float: left;
  width: 100%; }
  .result-summary .date {
    font-size: 0.75rem; }
  .result-summary button {
    margin-bottom: 1rem; }
  .result-summary .header h1 {
    margin: 0.5rem 0;
    line-height: 1.365; }
    @media (max-width: 767px) {
      .result-summary .header h1 {
        line-height: 1.365; } }
    .result-summary .header h1 {
      font-size: 1.365rem; }
      @media screen and (min-width: 30rem) {
        .result-summary .header h1 {
          font-size: calc(1.365rem + 0.26 * ((100vw - 30rem) / 30)); } }
      @media screen and (min-width: 60rem) {
        .result-summary .header h1 {
          font-size: 1.625rem; } }
    .result-summary .header h1.name {
      margin-bottom: 0; }
    .result-summary .header h1 a {
      transition: color 300ms ease, border-color 300ms ease;
      color: #0081c6;
      border-bottom: 1px dashed transparent; }
      .result-summary .header h1 a:hover {
        color: #00a2f9;
        border-color: #0081c6; }
  .service .result-summary .header h1 {
    margin-top: 0.5rem;
    margin-bottom: 1rem; }
    .service .result-summary .header h1 {
      font-size: 1.5rem; }
      @media screen and (min-width: 30rem) {
        .service .result-summary .header h1 {
          font-size: calc(1.5rem + 0.5 * ((100vw - 30rem) / 30)); } }
      @media screen and (min-width: 60rem) {
        .service .result-summary .header h1 {
          font-size: 2rem; } }
  .result-summary .header:first-child h1 {
    margin-top: 0; }
  .result-summary .datetime {
    color: #222222;
    font-weight: bold;
    margin-bottom: 1rem; }
    .result-summary .datetime .date {
      display: block;
      font-size: 1.125rem; }
    .result-summary .datetime .time {
      display: block;
      font-size: 1.125rem; }
  .result-summary .description {
    line-height: 1.5; }
  .result-summary .richtext-content {
    font-size: 1rem; }
    @media screen and (min-width: 768px) {
      .result-summary .richtext-content {
        font-size: 1em; } }
  .result-summary .cite {
    position: relative;
    display: block;
    font-style: italic;
    color: #888888;
    margin-bottom: 1rem;
    font-size: 1rem; }
    .result-summary .cite::after {
      position: absolute;
      content: '';
      top: -0.5rem;
      left: 0;
      height: 1px;
      width: 40px;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0.25); }

.event-button,
.saveMySpot-container a {
  position: relative;
  display: block;
  border: 1px solid #0081c6;
  background-color: #fff;
  padding: 0.5rem 1rem;
  text-align: center;
  color: #0081c6;
  font-weight: bold;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border 300ms,  color 300ms 300ms, text-shadow 300ms 300ms, background-color 300ms 150ms; }
  .event-button .fa,
  .saveMySpot-container a .fa {
    margin-right: 0.5rem;
    position: absolute;
    left: 1rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    .event-button .fa + span,
    .saveMySpot-container a .fa + span {
      padding-left: 1.5rem; }
  .event-button:hover,
  .saveMySpot-container a:hover {
    background-color: #0081c6;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    border: 1px solid #006093; }
    .event-button:hover:before, .event-button:hover:after,
    .saveMySpot-container a:hover:before,
    .saveMySpot-container a:hover:after {
      width: 100%;
      transition-delay: 0s; }
  .event-button:before,
  .saveMySpot-container a:before {
    right: 0;
    top: 0; }
  .event-button:after,
  .saveMySpot-container a:after {
    left: 0;
    bottom: 0; }
  .event-button::before, .event-button::after,
  .saveMySpot-container a::before,
  .saveMySpot-container a::after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    transition: all 0.2s linear;
    background: #005da4; }

.graphic-container {
  display: block;
  width: 80px;
  height: 95px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 992px) {
    .graphic-container {
      width: 100px;
      height: 120px; } }
  .graphic-container.calendar {
    padding: 10px;
    padding-top: 20px;
    background-image: url("../images/graphic-calendar.png");
    width: 80px;
    height: 120px; }
    @media screen and (min-width: 992px) {
      .graphic-container.calendar {
        width: 100px;
        height: 145px; } }
    .graphic-container.calendar .month {
      display: block;
      text-align: center;
      background-color: #00a94f;
      color: #fff;
      font-size: 14px;
      padding: 0.25rem 0;
      font-weight: 900;
      margin-bottom: 0.25rem;
      text-transform: uppercase; }
    .graphic-container.calendar .day {
      text-align: center;
      color: #222222;
      font-size: 24px;
      line-height: 1;
      font-weight: 900;
      margin-bottom: 0.25rem; }
    .graphic-container.calendar .weekday {
      text-align: center;
      color: #888888;
      text-transform: uppercase;
      font-size: 10px; }
    @media screen and (min-width: 992px) {
      .graphic-container.calendar .month {
        font-size: 20px; }
      .graphic-container.calendar .day {
        font-size: 40px; }
      .graphic-container.calendar .weekday {
        font-size: 12px; } }
  .graphic-container.news {
    padding: 10px;
    background-image: url("../images/graphic-news.png"); }
    .graphic-container.news .title {
      color: #fff;
      text-transform: uppercase;
      font-size: 0.75rem;
      width: 100%;
      text-align: center;
      padding-top: 5px;
      padding-left: 14px; }
      @media screen and (min-width: 992px) {
        .graphic-container.news .title {
          padding-top: 12px; } }
  .graphic-container.file {
    padding: 10px;
    padding-top: 20px;
    background-image: url("../images/graphic-file.png");
    position: relative; }
    .graphic-container.file .type {
      position: absolute;
      right: 0;
      top: 45px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      padding: 0.125rem 0.5rem; }
      @media screen and (min-width: 992px) {
        .graphic-container.file .type {
          top: 60px; } }
      .pdf .graphic-container.file .type {
        background-color: #ed1c24; }
        .pdf .graphic-container.file .type:after {
          content: "PDF"; }
      .ppt .graphic-container.file .type {
        background-color: #ed1c24; }
        .ppt .graphic-container.file .type:after {
          content: "PPT"; }
      .doc .graphic-container.file .type {
        background-color: #0081c6; }
        .doc .graphic-container.file .type:after {
          content: "DOC"; }
      .xls .graphic-container.file .type {
        background-color: #00a94f; }
        .xls .graphic-container.file .type:after {
          content: "XLS"; }
  .graphic-container.story {
    padding: 10px;
    padding-top: 20px;
    background-image: url("../images/graphic-story.png"); }

._pastEvents {
  background: #f0efec;
  margin: 0 -15px;
  padding: 0 15px;
  position: relative; }
  ._pastEvents:first-of-type {
    margin-top: 2rem; }
    ._pastEvents:first-of-type .result-container {
      padding-top: 2rem; }
  ._pastEvents:last-of-type {
    background: #f0efec;
    /* Old browsers */
    background: -moz-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f0efec), color-stop(100%, transparent));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #f0efec 0%, transparent 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  ._pastEvents .past-events-header {
    background-color: #00a94f;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -1.25rem; }
    ._pastEvents .past-events-header + .result-container {
      padding-top: 3rem; }
    ._pastEvents .past-events-header h3 {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      padding: 10px 15px;
      margin: 0; }
  ._pastEvents .header h1 a {
    color: #222222; }
    ._pastEvents .header h1 a:hover {
      border-color: #222222; }
  ._pastEvents .month {
    background-color: #ccc !important; }
  ._pastEvents .day {
    color: #888888 !important; }
  ._pastEvents .event-button {
    display: none !important; }

.proximity-finder {
  margin-bottom: 1.5rem; }
  .proximity-finder .use-current-location {
    font-size: 1.125rem;
    margin-top: 1rem;
    line-height: 1.5; }
    .proximity-finder .use-current-location a {
      border-bottom: 1px dashed transparent;
      transition: border-color 300ms ease; }
      .proximity-finder .use-current-location a:hover {
        border-color: #0081c6; }

.location-details {
  display: block;
  width: 100%; }
  .location-details:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 480px) {
    .location-details {
      display: flex; } }
  .location-details .description {
    width: 100%;
    padding-right: 1rem; }
  .location-details .address span {
    display: block; }

.icon-split {
  display: block;
  position: relative;
  line-height: 1.5; }
  .icon-split .text-container {
    display: flex;
    font-family: Arial, Helvetica, sans-serif; }
  .icon-split .mileage {
    margin: 0;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: #000; }

.location-stats {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  margin-top: 1rem;
  float: left; }
  @media screen and (min-width: 480px) {
    .location-stats {
      width: 40%;
      min-width: 200px;
      margin-top: 0; } }
  @media screen and (min-width: 992px) {
    .location-stats {
      width: 30%;
      max-width: 260px; } }
  .location-stats .priority {
    text-align: center;
    font-weight: 700;
    font-size: 1.25rem;
    padding-top: 0.5rem; }
  .location-stats .plus-icon-container {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 1.5rem; }

.save-my-spot {
  padding: 0.25rem;
  padding-top: 0; }
  .save-my-spot.urgent {
    color: #ac208e; }
  .save-my-spot.emergency {
    color: #ed1c24; }
  .save-my-spot .wait {
    text-align: center;
    color: #555555; }
    .save-my-spot .wait span {
      font-weight: bold; }
      @media (max-width: 767px) {
        .save-my-spot .wait span {
          display: block;
          font-size: 1.5rem; } }
  .save-my-spot .saveMySpot-container {
    text-align: center;
    margin-top: 0.5rem; }
    .save-my-spot .saveMySpot-container a {
      border: 1px solid #0081c6;
      display: block;
      width: 100%;
      text-align: center;
      padding: 0.5rem; }

.doctor-wrapper {
  float: left;
  width: 60%; }
  @media (max-width: 767px) {
    .doctor-wrapper {
      width: 100%; } }
  .doctor-wrapper .position {
    line-height: 1.2;
    font-weight: 700;
    margin: 0.25rem 0;
    color: #222222;
    font-family: GothamRounded-Book; }
    .doctor-wrapper .position {
      font-size: 1rem; }
      @media screen and (min-width: 30rem) {
        .doctor-wrapper .position {
          font-size: calc(1rem + 0.125 * ((100vw - 30rem) / 30)); } }
      @media screen and (min-width: 60rem) {
        .doctor-wrapper .position {
          font-size: 1.125rem; } }

.doctor-location-wrapper {
  float: left;
  width: calc(40% - 1rem);
  margin-left: 1rem;
  padding-top: 0.5rem; }
  @media (max-width: 767px) {
    .doctor-location-wrapper {
      width: 100%;
      margin-left: 0; } }
  .doctor-location-wrapper ul {
    margin: 0;
    padding: 0; }
    .doctor-location-wrapper ul li {
      margin: 0;
      list-style-type: none; }

.doctor-locations li {
  position: relative;
  display: none; }
  .doctor-locations li + li {
    margin-top: 1rem; }
  .doctor-locations li.closest {
    display: block; }
  .modal-content .doctor-locations li {
    display: block; }

.doctor-locations .icon {
  position: absolute;
  top: 0;
  left: -1rem; }

.doctor-locations .see-all-locations {
  display: block; }

.network-badges {
  display: block;
  position: absolute;
  bottom: -15px;
  right: 10px; }
  .network-badges .network-badge {
    float: right;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-left: 5px;
    z-index: 100; }

#location-form + .side-nav-facets {
  border-top: 2px solid rgba(0, 0, 0, 0.1); }
  #location-form + .side-nav-facets:after {
    content: "";
    display: table;
    clear: both; }

.profile-header {
  position: relative;
  display: block;
  padding-top: 40px; }
  .profile-header:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 767px) {
    .profile-header {
      display: flex; }
      .profile-header .doctor-image {
        min-width: unset;
        width: auto; } }

.profile-summary {
  display: block; }
  @media screen and (min-width: 768px) {
    .profile-summary {
      width: 30%;
      max-width: 300px;
      float: left; } }

.profile-body {
  display: block;
  padding: 0 1.5rem; }
  @media (max-width: 767px) {
    .profile-body {
      padding: 0 1rem; } }
  @media screen and (min-width: 768px) {
    .profile-body {
      width: 70%;
      float: left; } }

.header-back {
  position: absolute;
  right: 0;
  top: 0;
  left: 0; }
  @media screen and (min-width: 768px) {
    .header-back {
      margin: 0;
      right: 1.5rem;
      top: 1.5rem;
      left: auto;
      z-index: 1; } }
  .header-back a.back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    padding: 0.5rem 1rem;
    font-family: GothamRounded-Book;
    font-weight: bold;
    color: white;
    background-color: #0081c6; }
    @media screen and (min-width: 768px) {
      .header-back a.back {
        position: relative;
        left: unset;
        width: auto;
        border-radius: 6px;
        background-color: transparent;
        color: #0081c6;
        border: 1px solid #0081c6; } }

.doctor-image {
  min-width: 110px;
  width: 30%;
  max-width: 300px;
  float: left;
  margin: 1rem 0.5rem; }
  @media screen and (min-width: 768px) {
    .doctor-image {
      padding-top: 0;
      margin: 0 auto;
      margin-bottom: -30px; } }
  .doctor-image .image-wrapper {
    position: relative;
    border-radius: 8px;
    min-width: 75px;
    width: 15vw;
    min-height: 100px;
    height: 20vw;
    margin: 0 auto;
    background-size: cover;
    background-position: center; }
    @media screen and (min-width: 768px) {
      .doctor-image .image-wrapper {
        border-radius: 12px;
        width: 160px;
        height: 200px; } }
  .doctor-image.male .image-wrapper {
    background-image: url("../images/profile-male.png"); }
  .doctor-image.female .image-wrapper {
    background-image: url("../images/profile-female.png"); }

.header-doctor {
  position: relative;
  display: block;
  display: flex;
  width: 100%;
  float: left; }
  @media screen and (min-width: 768px) {
    .header-doctor {
      padding-top: 0;
      display: block; } }

.mychart {
  display: block;
  padding: 1rem 0; }
  .mychart img {
    width: auto; }

.doctor-cape {
  background-color: #999;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 768px) {
    .doctor-cape {
      padding-top: 50px; } }
  .cpg .doctor-cape {
    background-color: #00a94f; }
    .cpg .doctor-cape .mychart {
      border-color: #00a94f; }
  .doctor-cape .mychart {
    background-color: white;
    text-align: center;
    padding: 1rem;
    border: 4px solid #999;
    margin-top: -1px; }
  .doctor-cape .makeappt {
    background-color: #0081C6;
    text-align: center;
    padding: 1rem;
    border: 4px solid #00a94f;
    margin-top: -1px;
    color: #fff !important;
    font-size: 1rem; }
  .doctor-cape .calendar-icon {
    font-family: 'choaGlyps' !important;
    color: white;
    padding: 6px 5px 0 5px;
    font-size: 1rem; }

.cape-group .cape-group-title, .cape-group .cape-group-item .group-item-title {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  font-family: GothamRounded-Book;
  font-weight: 500;
  font-size: 0.75rem; }

.cape-group {
  padding: 1rem; }
  .cape-group:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .cape-group .cape-group-title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700; }
  .cape-group .cape-group-item {
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.2;
    word-break: break-word; }
    .cape-group .cape-group-item a.network {
      color: #fff;
      font-size: 0.865em;
      line-height: 1;
      display: block;
      border-radius: 4px;
      padding: 8px;
      margin: -2px -8px;
      transition: all 300ms ease; }
      .cape-group .cape-group-item a.network:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #ffdd00; }
      .cape-group .cape-group-item a.network:after {
        content: "";
        display: table;
        clear: both; }
    .cape-group .cape-group-item a:not(.network) {
      color: #ffdd00; }
    .cape-group .cape-group-item .group-item-icon {
      display: inline-block;
      width: 50px;
      height: 50px;
      float: left;
      background-color: rgba(0, 0, 0, 0.2);
      background-size: cover;
      border-radius: 4px;
      overflow: hidden;
      transition: all 300ms ease; }
    .cape-group .cape-group-item .group-item-title {
      margin-top: 1rem; }
    .cape-group .cape-group-item .group-item-text {
      width: calc(100% - 50px);
      min-height: 50px;
      float: left;
      line-height: 1;
      padding-left: 0.5rem;
      position: relative; }
      .cape-group .cape-group-item .group-item-text span {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }

.doctor-tag {
  position: relative; }
  .doctor-tag .name {
    color: #00a94f;
    font-family: GothamRounded-Book;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.5rem; }
    @media (min-width: 1024px) {
      .doctor-tag .name {
        font-size: 2rem; } }
  .doctor-tag .specialty {
    color: #222222;
    font-family: GothamRounded-Book;
    font-weight: bold;
    color: #222222;
    font-size: 1.25rem;
    line-height: 1.25; }
    @media (min-width: 1024px) {
      .doctor-tag .specialty {
        font-size: 1.5rem; } }

.specs {
  font-size: 1rem;
  margin-bottom: 1rem; }
  .specs.specs-table {
    display: flex;
    flex-wrap: wrap; }
  .specs .spec-wrapper {
    margin-right: 1rem; }
  .specs .spec {
    text-transform: initial;
    font-size: 1rem;
    color: #222222; }

.label-container {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 60px;
  text-align: center;
  display: block;
  background-image: linear-gradient(to bottom, transparent, white 50%); }
  .label-container #read-more-label {
    background-color: white;
    color: #0081c6;
    font-family: GothamRounded-Book;
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0; }
    .label-container #read-more-label:before {
      content: '- Read More -'; }

#read-more-trigger {
  display: none; }
  #read-more-trigger:checked ~ .label-container {
    background-image: linear-gradient(to bottom, transparent, transparent 50%); }
    #read-more-trigger:checked ~ .label-container #read-more-label:before {
      content: '- Read Less -'; }
    #read-more-trigger:checked ~ .label-container .shadow {
      padding: 0; }
  #read-more-trigger:checked ~ .line-clamp {
    max-height: 1000em; }

.read-more {
  position: absolute;
  display: block;
  font-family: GothamRounded-Book;
  font-weight: bold;
  bottom: 2px;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 60px 0 0;
  background-image: linear-gradient(to bottom, transparent, white 50%); }
  .read-more a {
    background-color: white;
    padding: 0.25rem 2rem;
    position: relative; }
    .read-more a:hover:before, .read-more a:hover:after {
      color: #0081c6; }
    .read-more a:hover:before {
      left: -10px; }
    .read-more a:hover:after {
      right: -10px; }
    .read-more a:before, .read-more a:after {
      color: rgba(0, 0, 0, 0.1);
      position: relative;
      -webkit-transition: 300ms ease;
      -khtml-transition: 300ms ease;
      -moz-transition: 300ms ease;
      -ms-transition: 300ms ease;
      -o-transition: 300ms ease;
      transition: 300ms ease; }
    .read-more a:before {
      left: -20px;
      content: " [ "; }
    .read-more a:after {
      right: -20px;
      content: " ] "; }

.bio {
  font-size: 1.25em;
  position: relative;
  margin-bottom: 1.5rem; }
  .bio p {
    margin: 0;
    padding-bottom: 1rem; }
  .bio .line-clamp {
    max-height: 180px;
    padding-bottom: 1.5rem;
    transition: max-height 600ms ease;
    overflow: hidden; }

.profile-details {
  display: flex;
  flex-flow: row wrap;
  height: 100%; }
  .profile-details > * {
    flex: 1 100%; }
  .profile-details .details {
    display: block;
    float: left;
    padding-bottom: 1.5rem;
    font-size: 1rem; }
    .profile-details .details h2 {
      border-bottom: 1px solid #333;
      font-size: 1.5rem;
      padding: 1rem 0;
      padding-top: 0;
      margin-top: 0; }
    .profile-details .details ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 1rem;
      color: #00a94f; }
      .profile-details .details ul li {
        position: relative;
        padding-left: 20px; }
        .profile-details .details ul li:before {
          content: "\f0c8";
          font-family: FontAwesome;
          color: #00a94f;
          font-size: 9px;
          position: absolute;
          left: 0px;
          top: 5px; }
        .profile-details .details ul li span {
          color: #555555; }
    .profile-details .details.full {
      width: 100%; }
    .profile-details .details.half {
      width: 49%;
      flex: 2 49%; }
      .profile-details .details.half + .half {
        width: 49%;
        margin-left: 2%; }
      .profile-details .details.half:only-child {
        width: 100%;
        margin: 0; }

.media-item + .media-item {
  margin-top: 1.5rem; }

.media-item h3 {
  margin-top: 0; }

.media-item time {
  color: #222222; }

.media-item cite {
  position: relative;
  color: #888888; }
  .media-item cite:after {
    position: absolute;
    content: " ";
    top: -0.5rem;
    left: 0;
    display: block;
    width: 3rem;
    height: 1px;
    background-color: #333; }

.location {
  position: relative;
  padding: 0 1rem;
  font-size: 1rem; }
  .location:before {
    content: "\f041";
    font-family: FontAwesome;
    color: #888888;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0; }
  .location h3 {
    font-size: 1rem;
    margin-bottom: 0.25rem; }
  .location address {
    margin-bottom: 0; }
  .location .link {
    display: block; }

.media-wrapper {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 992px) {
    .media-wrapper {
      float: right;
      margin-left: 1rem; } }
  .media-wrapper:last-child {
    width: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important; }
  .media-wrapper.map {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative; }
    @media screen and (min-width: 992px) {
      .media-wrapper.map {
        width: 30vw;
        max-width: 400px;
        height: 30vw;
        max-height: 400px; } }
    .media-wrapper.map #map {
      background-color: rgba(0, 0, 0, 0.1);
      border: none;
      margin: 0;
      height: 100%; }
  .media-wrapper.video {
    width: 100%; }
    .media-wrapper.video .embed-responsive {
      background-color: rgba(0, 0, 0, 0.1); }
    @media screen and (min-width: 992px) {
      .media-wrapper.video {
        width: 30vw;
        max-width: 400px; } }

.thumb-wrapper .poster {
  z-index: 10; }

.map-link {
  text-align: center; }
  .map-link button {
    background-color: transparent;
    padding: 0.25rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-color: #0081c6;
    border-radius: 4px;
    font-weight: bold;
    color: #0081c6; }
    @media screen and (min-width: 992px) {
      .map-link button {
        display: none; } }

.map {
  display: none; }
  @media screen and (min-width: 992px) {
    .map {
      display: block; } }

.side-facets .nav-title,
.center-facets .nav-title {
  padding: 0; }

.side-facets .radio-container [type=radio],
.center-facets .radio-container [type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .side-facets .radio-container [type=radio] + span:before,
  .center-facets .radio-container [type=radio] + span:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 1em;
    box-shadow: 0 0 0 2px #666;
    vertical-align: top;
    margin: 3px 10px 0 0; }
    @media (min-width: 992px) {
      .side-facets .radio-container [type=radio] + span:before,
      .center-facets .radio-container [type=radio] + span:before {
        width: 10px;
        height: 10px;
        margin: 3px 5% 0 0;
        box-shadow: 0 0 0 1.5px #666; } }
  .side-facets .radio-container [type=radio]:checked + span:before,
  .center-facets .radio-container [type=radio]:checked + span:before {
    background: #00a94f;
    box-shadow: 0 0 0 2px #666; }

.side-facets .filter-options div.filter-see-all .filter-see-all-toggle,
.center-facets .filter-options div.filter-see-all .filter-see-all-toggle {
  position: relative;
  padding-left: 30px; }
  .side-facets .filter-options div.filter-see-all .filter-see-all-toggle p, .side-facets .filter-options div.filter-see-all .filter-see-all-toggle i,
  .center-facets .filter-options div.filter-see-all .filter-see-all-toggle p,
  .center-facets .filter-options div.filter-see-all .filter-see-all-toggle i {
    color: #0081c6; }
  .side-facets .filter-options div.filter-see-all .filter-see-all-toggle i,
  .center-facets .filter-options div.filter-see-all .filter-see-all-toggle i {
    position: absolute;
    left: 0;
    top: 0.125rem; }

.side-facets .filter-options ul li input,
.center-facets .filter-options ul li input {
  font-size: 1em;
  position: absolute; }

.side-facets .filter-options ul li input[type=checkbox] + label:before,
.center-facets .filter-options ul li input[type=checkbox] + label:before {
  width: 30px; }

.date-dropdown select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%; }
  .date-dropdown select option {
    padding-left: 1rem;
    font-size: 1rem; }
    .date-dropdown select option.year {
      background-color: rgba(0, 0, 0, 0.05);
      padding-left: 0;
      font-weight: 700;
      font-size: 1.125rem; }
    .date-dropdown select option.month {
      padding-left: 1rem;
      font-size: 1rem; }

.date-dropdown .form-control {
  height: auto; }

.date-dropdown .ui-selectmenu-button.ui-button {
  display: block;
  width: 100%;
  line-height: 24px;
  background: #fff;
  border-top-left-radius: none !important; }

.ui-selectmenu-menu {
  z-index: 1100;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.6); }
  .ui-selectmenu-menu .ui-widget-content {
    padding: 10px; }
  .ui-selectmenu-menu.ui-front {
    z-index: 1100; }

.ui-selectmenu-optgroup ~ .ui-menu-item .ui-menu-item-wrapper {
  padding-left: 1rem; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active, .ui-button:active,
.ui-button.ui-state-active:hover {
  border-width: 1px;
  background: rgba(0, 0, 0, 0.05);
  font-weight: normal;
  color: #212121; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: none; }

.ui-selectmenu-button-open .ui-selectmenu-icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg); }

.side-facets {
  display: block; }
  @media screen and (min-width: 992px) {
    .side-facets {
      width: 30%;
      float: left;
      padding: 1.5rem 0.75rem; } }
  .side-facets form {
    margin-bottom: 1rem; }

.center-facets {
  position: relative;
  max-height: 0;
  margin: 0 -0.75rem;
  overflow: hidden;
  transition: max-height 0.75s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05) inset; }
  .center-facets #facets {
    position: relative;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding-bottom: 1.5rem;
    margin-bottom: 1rem; }
  .center-facets .filter-submit {
    max-width: unset !important; }
  .center-facets.open {
    max-height: 9999px; }
  .center-facets .close-facets {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    bottom: -1rem;
    z-index: 1000; }
    .center-facets .close-facets button {
      background-color: #0081c6;
      color: #fff;
      padding: 0.25rem 0.5rem;
      border: 1px solid #0081c6;
      border-radius: 4px;
      text-transform: uppercase;
      transition: all 100ms ease; }
      .center-facets .close-facets button:hover {
        background-color: #006093; }

.close-facets {
  display: none; }

#facets {
  display: none; }
  @media screen and (min-width: 992px) {
    #facets {
      display: block; } }

.facet-graphic-list label {
  min-height: 32px; }
  .facet-graphic-list label .facet-graphic-container {
    position: absolute;
    top: -6px;
    left: 24px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 4px; }
  .facet-graphic-list label p {
    padding-left: 30px; }

.search-results {
  display: block;
  padding: 1.5rem 0.75rem; }
  @media screen and (min-width: 992px) {
    .search-results {
      width: 70%;
      float: left;
      padding-right: 1.5rem; } }

.search-results .input-group ::-ms-clear,
.side-facets .input-group ::-ms-clear {
  width: 0;
  height: 0; }

.search-results .input-group .fa-remove:before,
.search-results .input-group .fa-close:before,
.search-results .input-group .fa-times:before,
.side-facets .input-group .fa-remove:before,
.side-facets .input-group .fa-close:before,
.side-facets .input-group .fa-times:before {
  content: "\f00d"; }

.nav-tabs {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 767px) {
  .nav-tabs.search {
    margin: 0 -15px;
    font-size: 0.7rem; } }

.facet-disclaimer, .specialty-disclaimer {
  font-size: 0.865rem;
  font-style: italic;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 1rem; }

.specialty-disclaimer {
  margin-top: 0;
  margin-bottom: 1rem; }

.side-nav-facets .nav + .nav {
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.side-nav-facets > .nav {
  overflow: hidden;
  margin: 0; }
  .side-nav-facets > .nav li:not(.nav-item) {
    position: relative;
    padding-left: 30px; }
    .side-nav-facets > .nav li:not(.nav-item) :before {
      position: absolute;
      left: 0; }
    .side-nav-facets > .nav li:not(.nav-item) p {
      width: 100% !important; }
  .side-nav-facets > .nav .nav-item {
    background-color: transparent; }
    .side-nav-facets > .nav .nav-item .nav-title {
      background-color: transparent;
      color: #00a94f;
      font-family: GothamRounded-Book;
      font-size: 1.25rem !important;
      font-weight: bold;
      width: 100%;
      line-height: 1.25;
      padding: 0.25rem 0; }
    .side-nav-facets > .nav .nav-item .nav-arrow {
      background-color: transparent;
      padding: 1rem; }
      .side-nav-facets > .nav .nav-item .nav-arrow:before {
        color: #0081c6; }

.search-input-group {
  position: relative;
  display: flex;
  font-size: 1rem; }
  .search-input-group .form-control {
    height: auto; }
  @media screen and (max-width: 992px) {
    .search-input-group.primary .input-group {
      width: calc(100% - 60px) !important; } }
  .search-input-group .input-group {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.25);
    background-color: #fff; }
    .search-input-group .input-group input {
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 300;
      padding: 1rem 1.5rem;
      padding-right: 40px; }
  .search-input-group .search-submit {
    height: 100%; }
  .search-input-group .filter-button {
    display: block;
    background-color: transparent;
    margin-left: 10px;
    width: 50px;
    height: 65px;
    background-color: #0081c6;
    color: #fff;
    padding: 0.25rem 0.5rem;
    border: 1px solid #0081c6;
    border-radius: 4px;
    text-transform: uppercase;
    transition: all 100ms ease; }
    @media screen and (min-width: 992px) {
      .search-input-group .filter-button {
        display: none; } }
    .search-input-group .filter-button:hover {
      background-color: #006093; }
  .search-input-group .input-group-btn {
    width: 50px; }
  .search-input-group button[type=reset] {
    display: block;
    position: absolute;
    right: 50px;
    width: 50px;
    height: 100%;
    z-index: 10;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.25); }
    .search-input-group button[type=reset]:hover {
      color: rgba(0, 0, 0, 0.5); }
    .search-input-group button[type=reset] i:before {
      font-size: 1rem; }
  .search-input-group button[type=submit] {
    border-left: 1px solid #ddd;
    font-size: 1rem;
    transition: color 300ms ease;
    color: #0081c6; }
    .search-input-group button[type=submit]:hover {
      color: #00a2f9; }

input:not(:valid) ~ button[type=reset] {
  display: none; }

.modal-search .search-suggestions {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1100;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.6); }
  .modal-search .search-suggestions:empty {
    padding: 0 !important;
    border: 0 !important; }
  @media screen and (min-width: 992px) {
    .modal-search .search-suggestions {
      max-height: 50vh;
      overflow-y: scroll; } }
  @media screen and (max-height: 600px) and (orientation: landscape) {
    .modal-search .search-suggestions {
      max-height: 50vh;
      overflow-y: scroll; } }
  .modal-search .search-suggestions::-webkit-scrollbar {
    width: 40px; }
  .modal-search .search-suggestions::-webkit-scrollbar-track-piece {
    background-color: #FFF; }
  .modal-search .search-suggestions::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    outline: 2px solid #FFF;
    outline-offset: -2px;
    border: 12px solid transparent; }
  .modal-search .search-suggestions::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.25); }
  .modal-search .search-suggestions ul {
    padding: 0;
    margin: 1rem 0; }
    .modal-search .search-suggestions ul li {
      list-style-type: none;
      padding: 0.25rem 1rem; }
  .modal-search .search-suggestions .ui-autocomplete-term {
    font-weight: bold;
    color: #003f60; }
  .modal-search .search-suggestions .ui-autocomplete-category {
    font-weight: bold;
    font-family: GothamRounded-Book;
    color: #000;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    margin-top: 1rem; }
    .modal-search .search-suggestions .ui-autocomplete-category {
      font-size: 1rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .search-suggestions .ui-autocomplete-category {
          font-size: calc(1rem + 0.125 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .search-suggestions .ui-autocomplete-category {
          font-size: 1.125rem; } }
    .modal-search .search-suggestions .ui-autocomplete-category:first-of-type {
      border: none;
      margin-top: 0; }
  .modal-search .search-suggestions .ui-menu-item {
    font-family: GothamRounded-Book;
    transition: all 300ms ease; }
    .modal-search .search-suggestions .ui-menu-item {
      font-size: 1rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .search-suggestions .ui-menu-item {
          font-size: calc(1rem + 0.5 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .search-suggestions .ui-menu-item {
          font-size: 1.5rem; } }
    .modal-search .search-suggestions .ui-menu-item span {
      color: #999; }
      .modal-search .search-suggestions .ui-menu-item span {
        font-size: 1rem; }
        @media screen and (min-width: 20rem) {
          .modal-search .search-suggestions .ui-menu-item span {
            font-size: calc(1rem + 0.25 * ((100vw - 20rem) / 55)); } }
        @media screen and (min-width: 75rem) {
          .modal-search .search-suggestions .ui-menu-item span {
            font-size: 1.25rem; } }
    .modal-search .search-suggestions .ui-menu-item a {
      color: #0081c6;
      font-weight: bold; }
      .modal-search .search-suggestions .ui-menu-item a b {
        color: #003f60;
        font-weight: 900; }
      .modal-search .search-suggestions .ui-menu-item a:hover {
        background-color: transparent;
        color: #00a2f9; }
    .modal-search .search-suggestions .ui-menu-item .badge {
      color: #333; }
      .modal-search .search-suggestions .ui-menu-item .badge.count {
        background-color: transparent; }
        .modal-search .search-suggestions .ui-menu-item .badge.count:before {
          content: "(";
          color: rgba(0, 0, 0, 0.25);
          margin-right: 2px; }
        .modal-search .search-suggestions .ui-menu-item .badge.count:after {
          content: ")";
          color: rgba(0, 0, 0, 0.25);
          margin-left: 2px; }

.sort-container {
  position: relative;
  min-height: 1.5rem;
  margin: 0.25rem 0; }

.sort {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .sort li {
    display: inline; }
  .sort .arrow {
    display: none;
    width: 0;
    height: 0; }
    .sort .arrow.down {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #0081c6; }
  .sort .sort-option {
    position: relative;
    padding: 0 0.25rem;
    cursor: pointer; }
    .sort .sort-option.active {
      font-weight: bold; }
      .sort .sort-option.active .arrow {
        display: block;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%); }
    .sort .sort-option + .sort-option {
      border-left: 1px solid rgba(0, 0, 0, 0.1); }

.results {
  position: absolute;
  right: 0;
  top: 0;
  color: #888888; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/*.nav.navbar-nav {
    margin:auto;
}
div#navbar div.container-fluid.navbar-bg-col ul#primary-nav {
    margin-left: auto !important;
    margin-top:-15px;
    @include ctr(y);
    left:200px;
}*/
html {
  font-size: 18px; }

body {
  font-family: GothamRounded-Book;
  color: #555555;
  overflow-x: hidden; }
  body.modal-open {
    overflow: hidden; }

h1, h2, h3 {
  font-family: GothamRounded-Book;
  font-weight: bold;
  color: #222222; }

h4 {
  font-weight: bold; }

strong {
  font-weight: bold; }

p {
  line-height: 1.5;
  margin-bottom: 1rem; }

.sub-head,
.nav-title {
  font-weight: bold; }

.related .relatedChild p {
  font-weight: bold; }

a.page-link, a.english-spanish-link {
  font-weight: 700; }

a:hover {
  text-decoration: none; }

/*.navbar-default .navbar-nav>li>a:hover {
    color:$choa-yellow;
}*/
.page-navigation {
  font-family: GothamRounded-Book;
  font-weight: 700; }

.filter-options label {
  transition: all 300ms ease; }
  .filter-options label p, .filter-options label:before {
    transition: all 300ms ease; }
  .filter-options label:hover {
    color: #000 !important; }
    .filter-options label:hover p, .filter-options label:hover:before {
      color: #000 !important; }

ul.breadcrumbs li a,
.panel-body a, p.subhead {
  font-weight: bold; }

.facility .info .location .name {
  font-weight: bold; }


@charset "UTF-8";
.text-color-red,
.color-red,
.text-red,
.choa-red {
  color: #ed1c24; }

.text-color-gold,
.color-gold,
.text-gold,
.choa-gold {
  color: #f6b221; }

.text-color-yellow,
.color-yellow,
.text-yellow,
.choa-yellow {
  color: #ffdd00; }

.text-color-green,
.color-green,
.text-green,
.choa-green {
  color: #00a94f; }

.text-color-lightblue,
.color-lightblue,
.text-lightblue,
.choa-lightblue {
  color: #4bbbeb; }

.text-color-blue,
.color-blue,
.text-blue,
.choa-blue {
  color: #0081c6; }

.text-color-darkblue,
.color-darkblue,
.text-darkblue,
.choa-darkblue {
  color: #005da4; }

*:not(.nav li a) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word; }

.result-container .richtext-content {
  word-break: break-word;
  font-family: GothamRounded-Book;
  padding-bottom: 1.5rem; }
  .result-container .richtext-content br + br {
    display: none; }
  .result-container .richtext-content ul {
    margin: 0;
    padding: 0; }
    .result-container .richtext-content ul li {
      list-style-type: none;
      color: #555555;
      padding-left: 1rem;
      position: relative; }
      .result-container .richtext-content ul li:before {
        content: '■';
        color: #00a94f;
        font-size: 14px;
        position: absolute;
        left: 0;
        top: 0; }


.badge-cpg {
  background-image: url(../images/badge-CPG.png);
  background-size: cover; }

.badge-tccn {
  background-image: url(../images/badge-TCCN.png);
  background-size: cover; }

.facet-graphic-container[class*="location-"] {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; }

.facet-graphic-container.location-emergency {
  background-image: url(../../assets/images/map-pin-hospital-small.png); }

.facet-graphic-container.location-urgent {
  background-image: url(../../assets/images/map-pin-urgent-care-small.png); }

.facet-graphic-container.location-telemedicine {
  background-image: url(../../assets/images/map-pin-telemed-small.png); }

.facet-graphic-container.location-neighborhood {
  background-image: url(../../assets/images/map-pin-clinic-small.png); }

.modal-backdrop {
  background-color: transparent;
  transition: all 300ms ease; }

.modal-backdrop.in {
  background-color: rgba(150, 150, 150, 0.8);
  opacity: 0.9;
  visibility: visible; }

.modal {
  z-index: 1050; }
  .modal.in {
    padding-right: 0 !important; }

.modal-content {
  height: auto;
  opacity: 1; }

.modal-lite {
  padding: 2rem 0;
  overflow-y: scroll; }
  .modal-lite .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.6); }
  .modal-lite .close {
    position: fixed;
    top: 1rem;
    right: 2rem;
    padding: 1rem;
    width: 60px;
    height: 60px;
    background-color: #00a94f;
    color: white;
    font-size: 30px;
    line-height: 30px;
    opacity: 1;
    transition: all 300ms ease;
    z-index: 1; }
    .modal-lite .close span {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .modal-lite .close:hover {
      background-color: #007637; }

.modal-search .modal-content {
  box-shadow: none;
  border: none;
  padding: 0;
  padding-top: 3rem;
  height: 100%;
  opacity: 1;
  border-radius: 0;
  background-color: transparent; }
  .modal-search .modal-content .modal-search-form {
    height: auto; }
    .modal-search .modal-content .modal-search-form > .input-group {
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.5rem; }
  .modal-search .modal-content .searchbox-input {
    line-height: 1em;
    padding: 0.5rem 1rem;
    padding-right: 15%;
    /*Declared in production as 1.5rem but it causes the text to go to the next line on mobile*/
    box-sizing: border-box;
    height: 15vw;
    max-height: 80px; }
    .modal-search .modal-content .searchbox-input {
      font-size: 1.3rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .modal-content .searchbox-input {
          font-size: calc(1.3rem + 1.7 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .modal-content .searchbox-input {
          font-size: 3rem; } }
    @media (min-width: 1024px) {
      .modal-search .modal-content .searchbox-input {
        padding-right: 10%;
        height: 10vw;
        max-height: 100px; } }
  .modal-search .modal-content .searchbox-submit-wrapper:before {
    content: "\f002";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit; }
  .modal-search .modal-content .searchbox-submit {
    position: relative;
    border-left: none;
    z-index: 10;
    background-color: transparent;
    text-transform: uppercase;
    line-height: 1em;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    width: 15vw;
    max-width: 80px;
    height: 15vw;
    max-height: 80px;
    transition: color 300ms ease;
    color: #0081c6; }
    .modal-search .modal-content .searchbox-submit:hover {
      color: #00a2f9; }
    @media (min-width: 1024px) {
      .modal-search .modal-content .searchbox-submit {
        width: 10vw;
        max-width: 100px;
        height: 10vw; } }
    .modal-search .modal-content .searchbox-submit .fa-search {
      font-size: 1.25rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .modal-content .searchbox-submit .fa-search {
          font-size: calc(1.25rem + 0.75 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .modal-content .searchbox-submit .fa-search {
          font-size: 2rem; } }
  .modal-search .modal-content .searchbox-reset {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 12vw;
    color: rgba(0, 0, 0, 0.1);
    z-index: 10;
    height: 100%;
    width: 100px;
    transition: all ease 300ms; }
    @media (min-width: 1024px) {
      .modal-search .modal-content .searchbox-reset {
        right: 100px; } }
    .modal-search .modal-content .searchbox-reset .fa-close {
      font-size: 1rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .modal-content .searchbox-reset .fa-close {
          font-size: calc(1rem + 0.5 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .modal-content .searchbox-reset .fa-close {
          font-size: 1.5rem; } }
    .modal-search .modal-content .searchbox-reset .fa-close:before {
      content: "\f00d"; }
    .modal-search .modal-content .searchbox-reset:hover {
      color: rgba(0, 0, 0, 0.25); }
  .modal-search .modal-content .close {
    position: relative;
    padding: 1rem;
    width: 60px;
    height: 60px;
    background-color: #00a94f;
    color: white;
    font-size: 30px;
    line-height: 30px;
    opacity: 1;
    transition: all 300ms ease; }
    .modal-search .modal-content .close span {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .modal-search .modal-content .close:hover {
      background-color: #007637; }

.location-content {
  font-size: 1rem; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1;
  /* Firefox */ }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc; }

input[type=search] {
  -webkit-appearance: none; }

.nav-tabs.search li {
  margin: 0;
  width: 25%; }
  @media screen and (min-width: 768px) {
    .nav-tabs.search li {
      width: auto; } }
  .nav-tabs.search li:hover a, .nav-tabs.search li:hover i {
    color: black; }
  .nav-tabs.search li:hover a {
    border: 1px solid transparent; }
  .nav-tabs.search li a {
    padding: 0.25rem 0.5rem !important;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    margin-bottom: -2px; }
    @media screen and (min-width: 768px) {
      .nav-tabs.search li a {
        padding: 0.5rem 1rem !important;
        font-size: 1rem; } }
    .nav-tabs.search li a i {
      display: block;
      font-size: 1.5rem;
      padding: 0.25rem; }
      @media screen and (min-width: 768px) {
        .nav-tabs.search li a i {
          display: inline;
          font-size: 1rem; } }
  .nav-tabs.search li.active a {
    border: none;
    color: #00a94f;
    border-bottom: 8px solid #00a94f; }
  .nav-tabs.search li.active i {
    color: #00a94f; }

.search-results span.pagination,
.search-results div.pagination {
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  display: block; }
  .search-results span.pagination .pagination-next a,
  .search-results span.pagination .pagination-prev a,
  .search-results div.pagination .pagination-next a,
  .search-results div.pagination .pagination-prev a {
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .search-results span.pagination .pagination-next a:hover,
    .search-results span.pagination .pagination-prev a:hover,
    .search-results div.pagination .pagination-next a:hover,
    .search-results div.pagination .pagination-prev a:hover {
      border: 1px solid rgba(0, 0, 0, 0.1); }
  .search-results span.pagination nav,
  .search-results div.pagination nav {
    background-color: transparent; }
  .search-results span.pagination li a, .search-results span.pagination li span,
  .search-results div.pagination li a,
  .search-results div.pagination li span {
    background-color: transparent;
    cursor: pointer; }
    .search-results span.pagination li a:hover, .search-results span.pagination li span:hover,
    .search-results div.pagination li a:hover,
    .search-results div.pagination li span:hover {
      background-color: transparent;
      color: #000;
      font-weight: 700; }
  .search-results span.pagination li.disabled:hover a,
  .search-results div.pagination li.disabled:hover a {
    font-weight: 300;
    color: rgba(100, 100, 100, 0.5); }
  .search-results span.pagination li.active a,
  .search-results div.pagination li.active a {
    background-color: #00a94f;
    color: #fff;
    font-weight: 900; }

.range-slider-wrapper {
  padding: 15px; }

.toggle-filters {
  display: inline-block;
  padding: 15px 10px;
  margin-top: 15px;
  margin-left: 15px;
  cursor: pointer;
  background-color: #999;
  color: #eee;
  min-width: 162px;
  text-align: center;
  border-radius: 5px;
  transition: all .6s ease; }
  .toggle-filters:hover {
    background-color: #932222;
    color: #fff; }

.slider-heading {
  display: block;
  font-size: 17px;
  font-style: italic;
  margin-bottom: 50px;
  margin-left: 15px; }

.slider {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;
  border-radius: 6px; }
  .slider .ui-slider-handle {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #00a94f;
    border-radius: 100%;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none; }

.value {
  display: block;
  position: absolute;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  font-size: 11px; }

.value::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: rgba(0, 0, 0, 0.1);
  border-width: 5px;
  margin-left: -5px; }

.ui-state-focus,
.ui-state-hover {
  border-color: transparent !important; }

.ui-slider-range-min {
  background: #00a94f; }

.tooltip {
  background-color: transparent;
  width: auto !important;
  opacity: 1 !important; }
  .tooltip .tooltip-inner {
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.25);
    z-index: 1020;
    max-width: unset;
    width: auto;
    min-width: 80vw; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .tooltip .tooltip-inner {
        min-width: 50vw !important; } }
    @media (min-width: 1024px) {
      .tooltip .tooltip-inner {
        min-width: 30vw; } }
  .tooltip .tooltip-content {
    text-align: left;
    padding-left: 80px;
    min-height: 70px; }
  .tooltip .tooltip-arrow {
    top: 0; }
  .tooltip .network-badge {
    position: absolute;
    width: 60px !important;
    height: 60px !important;
    left: 1rem;
    top: 1rem; }
  .tooltip .tooltip-content-title {
    font-weight: bold;
    font-size: 1.125rem;
    font-family: GothamRounded-Book; }
  .tooltip .tooltip-content-description {
    font-size: 0.865rem; }

.video-container {
  height: 0;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 1rem; }
  .video-container a {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: white;
    font-size: 2em;
    text-align: center;
    line-height: 1.6em;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 300ms ease; }
    .video-container a span {
      visibility: hidden; }
    .video-container a:before {
      content: "\f04b";
      font-family: FontAwesome;
      box-sizing: content-box;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      height: 1.6em;
      width: 1.6em;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 100;
      border-radius: 50%;
      padding-left: 4px;
      border: 2px solid white;
      transition: all 300ms ease; }
    .video-container a:hover {
      background-color: rgba(0, 0, 0, 0); }
      .video-container a:hover:before {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0);
        border-color: rgba(255, 255, 255, 0.2); }
  .video-container iframe,
  .video-container object,
  .video-container embed,
  .video-container img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.video-details {
  margin-top: 1rem; }
  .video-details h3 {
    margin-top: 0; }

.profile-header {
  position: relative;
  display: block;
  padding-top: 40px; }
  .profile-header:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 767px) {
    .profile-header {
      display: flex; }
      .profile-header .doctor-image {
        min-width: unset;
        width: auto; } }

.profile-summary {
  display: block; }
  @media screen and (min-width: 768px) {
    .profile-summary {
      width: 30%;
      max-width: 300px;
      float: left; } }

.profile-body {
  display: block;
  padding: 0 1.5rem; }
  @media (max-width: 767px) {
    .profile-body {
      padding: 0 1rem; } }
  @media screen and (min-width: 768px) {
    .profile-body {
      width: 70%;
      float: left; } }

.header-back {
  position: absolute;
  right: 0;
  top: 0;
  left: 0; }
  @media screen and (min-width: 768px) {
    .header-back {
      margin: 0;
      right: 1.5rem;
      top: 1.5rem;
      left: auto;
      z-index: 1; } }
  .header-back a.back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    padding: 0.5rem 1rem;
    font-family: GothamRounded-Book;
    font-weight: bold;
    color: white;
    background-color: #0081c6; }
    @media screen and (min-width: 768px) {
      .header-back a.back {
        position: relative;
        left: unset;
        width: auto;
        border-radius: 6px;
        background-color: transparent;
        color: #0081c6;
        border: 1px solid #0081c6; } }

.doctor-image {
  min-width: 110px;
  width: 30%;
  max-width: 300px;
  float: left;
  margin: 1rem 0.5rem; }
  @media screen and (min-width: 768px) {
    .doctor-image {
      padding-top: 0;
      margin: 0 auto;
      margin-bottom: -30px; } }
  .doctor-image .image-wrapper {
    position: relative;
    border-radius: 8px;
    min-width: 75px;
    width: 15vw;
    min-height: 100px;
    height: 20vw;
    margin: 0 auto;
    background-size: cover;
    background-position: center; }
    @media screen and (min-width: 768px) {
      .doctor-image .image-wrapper {
        border-radius: 12px;
        width: 160px;
        height: 200px; } }
  .doctor-image.male .image-wrapper {
    background-image: url("../images/profile-male.png"); }
  .doctor-image.female .image-wrapper {
    background-image: url("../images/profile-female.png"); }

.header-doctor {
  position: relative;
  display: block;
  display: flex;
  width: 100%;
  float: left; }
  @media screen and (min-width: 768px) {
    .header-doctor {
      padding-top: 0;
      display: block; } }

.mychart {
  display: block;
  padding: 1rem 0; }
  .mychart img {
    width: auto; }

.doctor-cape {
  background-color: #999;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 768px) {
    .doctor-cape {
      padding-top: 50px; } }
  .cpg .doctor-cape {
    background-color: #00a94f; }
    .cpg .doctor-cape .mychart {
      border-color: #00a94f; }
  .doctor-cape .mychart {
    background-color: white;
    text-align: center;
    padding: 1rem;
    border: 4px solid #999;
    margin-top: -1px; }
  .doctor-cape .makeappt {
    background-color: #0081C6;
    text-align: center;
    padding: 1rem;
    border: 4px solid #00a94f;
    margin-top: -1px;
    color: #fff !important;
    font-size: 1rem; }
  .doctor-cape .calendar-icon {
    font-family: 'choaGlyps' !important;
    color: white;
    padding: 6px 5px 0 5px;
    font-size: 1rem; }

.cape-group .cape-group-title, .cape-group .cape-group-item .group-item-title {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  font-family: GothamRounded-Book;
  font-weight: 500;
  font-size: 0.75rem; }

.cape-group {
  padding: 1rem; }
  .cape-group:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .cape-group .cape-group-title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700; }
  .cape-group .cape-group-item {
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.2;
    word-break: break-word; }
    .cape-group .cape-group-item a.network {
      color: #fff;
      font-size: 0.865em;
      line-height: 1;
      display: block;
      border-radius: 4px;
      padding: 8px;
      margin: -2px -8px;
      transition: all 300ms ease; }
      .cape-group .cape-group-item a.network:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #ffdd00; }
      .cape-group .cape-group-item a.network:after {
        content: "";
        display: table;
        clear: both; }
    .cape-group .cape-group-item a:not(.network) {
      color: #ffdd00; }
    .cape-group .cape-group-item .group-item-icon {
      display: inline-block;
      width: 50px;
      height: 50px;
      float: left;
      background-color: rgba(0, 0, 0, 0.2);
      background-size: cover;
      border-radius: 4px;
      overflow: hidden;
      transition: all 300ms ease; }
    .cape-group .cape-group-item .group-item-title {
      margin-top: 1rem; }
    .cape-group .cape-group-item .group-item-text {
      width: calc(100% - 50px);
      min-height: 50px;
      float: left;
      line-height: 1;
      padding-left: 0.5rem;
      position: relative; }
      .cape-group .cape-group-item .group-item-text span {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }

.doctor-tag {
  position: relative; }
  .doctor-tag .name {
    color: #00a94f;
    font-family: GothamRounded-Book;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.5rem; }
    @media (min-width: 1024px) {
      .doctor-tag .name {
        font-size: 2rem; } }
  .doctor-tag .specialty {
    color: #222222;
    font-family: GothamRounded-Book;
    font-weight: bold;
    color: #222222;
    font-size: 1.25rem;
    line-height: 1.25; }
    @media (min-width: 1024px) {
      .doctor-tag .specialty {
        font-size: 1.5rem; } }

.specs {
  font-size: 1rem;
  margin-bottom: 1rem; }
  .specs.specs-table {
    display: flex;
    flex-wrap: wrap; }
  .specs .spec-wrapper {
    margin-right: 1rem; }
  .specs .spec {
    text-transform: initial;
    font-size: 1rem;
    color: #222222; }

.label-container {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 60px;
  text-align: center;
  display: block;
  background-image: linear-gradient(to bottom, transparent, white 50%); }
  .label-container #read-more-label {
    background-color: white;
    color: #0081c6;
    font-family: GothamRounded-Book;
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0; }
    .label-container #read-more-label:before {
      content: '- Read More -'; }

#read-more-trigger {
  display: none; }
  #read-more-trigger:checked ~ .label-container {
    background-image: linear-gradient(to bottom, transparent, transparent 50%); }
    #read-more-trigger:checked ~ .label-container #read-more-label:before {
      content: '- Read Less -'; }
    #read-more-trigger:checked ~ .label-container .shadow {
      padding: 0; }
  #read-more-trigger:checked ~ .line-clamp {
    max-height: 1000em; }

.read-more {
  position: absolute;
  display: block;
  font-family: GothamRounded-Book;
  font-weight: bold;
  bottom: 2px;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 60px 0 0;
  background-image: linear-gradient(to bottom, transparent, white 50%); }
  .read-more a {
    background-color: white;
    padding: 0.25rem 2rem;
    position: relative; }
    .read-more a:hover:before, .read-more a:hover:after {
      color: #0081c6; }
    .read-more a:hover:before {
      left: -10px; }
    .read-more a:hover:after {
      right: -10px; }
    .read-more a:before, .read-more a:after {
      color: rgba(0, 0, 0, 0.1);
      position: relative;
      -webkit-transition: 300ms ease;
      -khtml-transition: 300ms ease;
      -moz-transition: 300ms ease;
      -ms-transition: 300ms ease;
      -o-transition: 300ms ease;
      transition: 300ms ease; }
    .read-more a:before {
      left: -20px;
      content: " [ "; }
    .read-more a:after {
      right: -20px;
      content: " ] "; }

.bio {
  font-size: 1.25em;
  position: relative;
  margin-bottom: 1.5rem; }
  .bio p {
    margin: 0;
    padding-bottom: 1rem; }
  .bio .line-clamp {
    max-height: 180px;
    padding-bottom: 1.5rem;
    transition: max-height 600ms ease;
    overflow: hidden; }

.profile-details {
  display: flex;
  flex-flow: row wrap;
  height: 100%; }
  .profile-details > * {
    flex: 1 100%; }
  .profile-details .details {
    display: block;
    float: left;
    padding-bottom: 1.5rem;
    font-size: 1rem; }
    .profile-details .details h2 {
      border-bottom: 1px solid #333;
      font-size: 1.5rem;
      padding: 1rem 0;
      padding-top: 0;
      margin-top: 0; }
    .profile-details .details ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 1rem;
      color: #00a94f; }
      .profile-details .details ul li {
        position: relative;
        padding-left: 20px; }
        .profile-details .details ul li:before {
          content: "\f0c8";
          font-family: FontAwesome;
          color: #00a94f;
          font-size: 9px;
          position: absolute;
          left: 0px;
          top: 5px; }
        .profile-details .details ul li span {
          color: #555555; }
    .profile-details .details.full {
      width: 100%; }
    .profile-details .details.half {
      width: 49%;
      flex: 2 49%; }
      .profile-details .details.half + .half {
        width: 49%;
        margin-left: 2%; }
      .profile-details .details.half:only-child {
        width: 100%;
        margin: 0; }

.media-item + .media-item {
  margin-top: 1.5rem; }

.media-item h3 {
  margin-top: 0; }

.media-item time {
  color: #222222; }

.media-item cite {
  position: relative;
  color: #888888; }
  .media-item cite:after {
    position: absolute;
    content: " ";
    top: -0.5rem;
    left: 0;
    display: block;
    width: 3rem;
    height: 1px;
    background-color: #333; }

.location {
  position: relative;
  padding: 0 1rem;
  font-size: 1rem; }
  .location:before {
    content: "\f041";
    font-family: FontAwesome;
    color: #888888;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0; }
  .location h3 {
    font-size: 1rem;
    margin-bottom: 0.25rem; }
  .location address {
    margin-bottom: 0; }
  .location .link {
    display: block; }

.media-wrapper {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 992px) {
    .media-wrapper {
      float: right;
      margin-left: 1rem; } }
  .media-wrapper:last-child {
    width: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important; }
  .media-wrapper.map {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative; }
    @media screen and (min-width: 992px) {
      .media-wrapper.map {
        width: 30vw;
        max-width: 400px;
        height: 30vw;
        max-height: 400px; } }
    .media-wrapper.map #map {
      background-color: rgba(0, 0, 0, 0.1);
      border: none;
      margin: 0;
      height: 100%; }
  .media-wrapper.video {
    width: 100%; }
    .media-wrapper.video .embed-responsive {
      background-color: rgba(0, 0, 0, 0.1); }
    @media screen and (min-width: 992px) {
      .media-wrapper.video {
        width: 30vw;
        max-width: 400px; } }

.thumb-wrapper .poster {
  z-index: 10; }

.map-link {
  text-align: center; }
  .map-link button {
    background-color: transparent;
    padding: 0.25rem 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-color: #0081c6;
    border-radius: 4px;
    font-weight: bold;
    color: #0081c6; }
    @media screen and (min-width: 992px) {
      .map-link button {
        display: none; } }

.map {
  display: none; }
  @media screen and (min-width: 992px) {
    .map {
      display: block; } }

.more-results {
  margin-bottom: 1rem; }
  .more-results .fa {
    font-weight: 900;
    margin-right: 10px; }

a.link span {
  border-bottom: 1px dashed transparent;
  transition: all 300ms ease; }

a.link:hover span {
  border-color: #0081c6; }

.tokens {
  display: block;
  margin-bottom: 1.5rem;
  margin-top: 1rem; }

.token {
  position: relative;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  padding-right: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  box-shadow: 1px 4px 2px -2px rgba(0, 0, 0, 0.1); }
  .token .close {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
    min-height: 1.5rem;
    color: #888888;
    transition: all 300ms ease;
    font-size: 24px; }
    .token .close:hover {
      color: #222222; }
    .token .close:active:hover {
      border-color: red; }

.filter-options .radio-container [type=radio] + span:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 1em;
  box-shadow: 0 0 0 2px #666;
  vertical-align: top;
  margin: 3px 5% 0 0; }
  @media (min-width: 992px) {
    .filter-options .radio-container [type=radio] + span:before {
      width: 10px;
      height: 10px;
      margin: 3px 5% 0 0;
      box-shadow: 0 0 0 1.5px #666; } }

.filter-see-all-toggle {
  position: relative;
  margin-top: 1rem; }
  .filter-see-all-toggle i {
    float: left; }
  .filter-see-all-toggle i, .filter-see-all-toggle p {
    color: #0081c6 !important;
    font-weight: 700; }
  .filter-see-all-toggle:hover i {
    animation: zoom 300ms ease 3; }

@keyframes zoom {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

.search-alternate {
  padding: 1rem 0;
  font-size: 1.25rem; }
  .search-alternate a {
    transition: color 300ms ease, border-color 300ms ease;
    color: #0081c6;
    border-bottom: 1px dashed transparent; }
    .search-alternate a:hover {
      color: #00a2f9;
      border-color: #0081c6; }
  .search-alternate:empty {
    padding: 0 !important; }

.result-container {
  font-size: 18px;
  width: 100%;
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  line-height: 1.5; }
  .result-container:after {
    content: "";
    display: table;
    clear: both; }
  .result-container .doctor-image {
    position: relative;
    width: 160px;
    margin-right: 1rem;
    margin-bottom: 0; }
    .result-container .doctor-image + .result-summary {
      width: calc(100% - 160px - 1rem); }
    @media (max-width: 767px) {
      .result-container .doctor-image {
        margin: 0;
        margin-top: 0.5rem;
        margin-right: 1rem;
        width: 85px;
        height: 120px;
        min-width: unset;
        max-width: unset; }
        .result-container .doctor-image .image-wrapper {
          width: 85px !important;
          height: 120px; }
        .result-container .doctor-image + .result-summary {
          width: calc(100% - 85px - 1rem); } }
  .result-container a {
    transition: color 300ms ease, border-color 300ms ease;
    color: #0081c6;
    border-bottom: 1px dashed transparent; }
    .result-container a:hover {
      color: #00a2f9;
      border-color: #0081c6; }
  .result-container:after {
    content: "";
    display: table;
    clear: both; }
  .result-container + .result-container {
    border-top: 2px solid rgba(0, 0, 0, 0.05); }

.result-container h4,
.appointment-title h4,
.spec-wrapper h4,
.specs h4 {
  font-weight: 700;
  font-size: 0.75rem;
  color: #222222;
  line-height: 1.2;
  margin-top: 1rem;
  margin-bottom: 0; }

.error-wrapper {
  display: block;
  width: 100%;
  padding: 1.5rem 1rem; }
  .error-wrapper .no-results {
    text-align: center;
    width: 100%;
    font-size: 1.25rem; }
    .error-wrapper .no-results span {
      color: #ed1c24;
      font-style: italic; }

.result-icon {
  width: 100px;
  float: left; }
  @media screen and (min-width: 992px) {
    .result-icon {
      width: 120px; } }
  .result-icon + .result-summary {
    width: calc(100% - 100px); }
    @media screen and (min-width: 992px) {
      .result-icon + .result-summary {
        width: calc(100% - 120px); } }

.result-summary {
  float: left;
  width: 100%; }
  .result-summary .date {
    font-size: 0.75rem; }
  .result-summary button {
    margin-bottom: 1rem; }
  .result-summary .header h1 {
    margin: 0.5rem 0;
    line-height: 1.365; }
    @media (max-width: 767px) {
      .result-summary .header h1 {
        line-height: 1.365; } }
    .result-summary .header h1 {
      font-size: 1.365rem; }
      @media screen and (min-width: 30rem) {
        .result-summary .header h1 {
          font-size: calc(1.365rem + 0.26 * ((100vw - 30rem) / 30)); } }
      @media screen and (min-width: 60rem) {
        .result-summary .header h1 {
          font-size: 1.625rem; } }
    .result-summary .header h1.name {
      margin-bottom: 0; }
    .result-summary .header h1 a {
      transition: color 300ms ease, border-color 300ms ease;
      color: #0081c6;
      border-bottom: 1px dashed transparent; }
      .result-summary .header h1 a:hover {
        color: #00a2f9;
        border-color: #0081c6; }
  .service .result-summary .header h1 {
    margin-top: 0.5rem;
    margin-bottom: 1rem; }
    .service .result-summary .header h1 {
      font-size: 1.5rem; }
      @media screen and (min-width: 30rem) {
        .service .result-summary .header h1 {
          font-size: calc(1.5rem + 0.5 * ((100vw - 30rem) / 30)); } }
      @media screen and (min-width: 60rem) {
        .service .result-summary .header h1 {
          font-size: 2rem; } }
  .result-summary .header:first-child h1 {
    margin-top: 0; }
  .result-summary .datetime {
    color: #222222;
    font-weight: bold;
    margin-bottom: 1rem; }
    .result-summary .datetime .date {
      display: block;
      font-size: 1.125rem; }
    .result-summary .datetime .time {
      display: block;
      font-size: 1.125rem; }
  .result-summary .description {
    line-height: 1.5; }
  .result-summary .richtext-content {
    font-size: 1rem; }
    @media screen and (min-width: 768px) {
      .result-summary .richtext-content {
        font-size: 1em; } }
  .result-summary .cite {
    position: relative;
    display: block;
    font-style: italic;
    color: #888888;
    margin-bottom: 1rem;
    font-size: 1rem; }
    .result-summary .cite::after {
      position: absolute;
      content: '';
      top: -0.5rem;
      left: 0;
      height: 1px;
      width: 40px;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0.25); }

.event-button,
.saveMySpot-container a {
  position: relative;
  display: block;
  border: 1px solid #0081c6;
  background-color: #fff;
  padding: 0.5rem 1rem;
  text-align: center;
  color: #0081c6;
  font-weight: bold;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border 300ms,  color 300ms 300ms, text-shadow 300ms 300ms, background-color 300ms 150ms; }
  .event-button .fa,
  .saveMySpot-container a .fa {
    margin-right: 0.5rem;
    position: absolute;
    left: 1rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    .event-button .fa + span,
    .saveMySpot-container a .fa + span {
      padding-left: 1.5rem; }
  .event-button:hover,
  .saveMySpot-container a:hover {
    background-color: #0081c6;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    border: 1px solid #006093; }
    .event-button:hover:before, .event-button:hover:after,
    .saveMySpot-container a:hover:before,
    .saveMySpot-container a:hover:after {
      width: 100%;
      transition-delay: 0s; }
  .event-button:before,
  .saveMySpot-container a:before {
    right: 0;
    top: 0; }
  .event-button:after,
  .saveMySpot-container a:after {
    left: 0;
    bottom: 0; }
  .event-button::before, .event-button::after,
  .saveMySpot-container a::before,
  .saveMySpot-container a::after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    transition: all 0.2s linear;
    background: #005da4; }

.graphic-container {
  display: block;
  width: 80px;
  height: 95px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 992px) {
    .graphic-container {
      width: 100px;
      height: 120px; } }
  .graphic-container.calendar {
    padding: 10px;
    padding-top: 20px;
    background-image: url("../images/graphic-calendar.png");
    width: 80px;
    height: 120px; }
    @media screen and (min-width: 992px) {
      .graphic-container.calendar {
        width: 100px;
        height: 145px; } }
    .graphic-container.calendar .month {
      display: block;
      text-align: center;
      background-color: #00a94f;
      color: #fff;
      font-size: 14px;
      padding: 0.25rem 0;
      font-weight: 900;
      margin-bottom: 0.25rem;
      text-transform: uppercase; }
    .graphic-container.calendar .day {
      text-align: center;
      color: #222222;
      font-size: 24px;
      line-height: 1;
      font-weight: 900;
      margin-bottom: 0.25rem; }
    .graphic-container.calendar .weekday {
      text-align: center;
      color: #888888;
      text-transform: uppercase;
      font-size: 10px; }
    @media screen and (min-width: 992px) {
      .graphic-container.calendar .month {
        font-size: 20px; }
      .graphic-container.calendar .day {
        font-size: 40px; }
      .graphic-container.calendar .weekday {
        font-size: 12px; } }
  .graphic-container.news {
    padding: 10px;
    background-image: url("../images/graphic-news.png"); }
    .graphic-container.news .title {
      color: #fff;
      text-transform: uppercase;
      font-size: 0.75rem;
      width: 100%;
      text-align: center;
      padding-top: 5px;
      padding-left: 14px; }
      @media screen and (min-width: 992px) {
        .graphic-container.news .title {
          padding-top: 12px; } }
  .graphic-container.file {
    padding: 10px;
    padding-top: 20px;
    background-image: url("../images/graphic-file.png");
    position: relative; }
    .graphic-container.file .type {
      position: absolute;
      right: 0;
      top: 45px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      padding: 0.125rem 0.5rem; }
      @media screen and (min-width: 992px) {
        .graphic-container.file .type {
          top: 60px; } }
      .pdf .graphic-container.file .type {
        background-color: #ed1c24; }
        .pdf .graphic-container.file .type:after {
          content: "PDF"; }
      .ppt .graphic-container.file .type {
        background-color: #ed1c24; }
        .ppt .graphic-container.file .type:after {
          content: "PPT"; }
      .doc .graphic-container.file .type {
        background-color: #0081c6; }
        .doc .graphic-container.file .type:after {
          content: "DOC"; }
      .xls .graphic-container.file .type {
        background-color: #00a94f; }
        .xls .graphic-container.file .type:after {
          content: "XLS"; }
  .graphic-container.story {
    padding: 10px;
    padding-top: 20px;
    background-image: url("../images/graphic-story.png"); }

._pastEvents {
  background: #f0efec;
  margin: 0 -15px;
  padding: 0 15px;
  position: relative; }
  ._pastEvents:first-of-type {
    margin-top: 2rem; }
    ._pastEvents:first-of-type .result-container {
      padding-top: 2rem; }
  ._pastEvents:last-of-type {
    background: #f0efec;
    /* Old browsers */
    background: -moz-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f0efec), color-stop(100%, transparent));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f0efec 0%, transparent 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #f0efec 0%, transparent 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  ._pastEvents .past-events-header {
    background-color: #00a94f;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -1.25rem; }
    ._pastEvents .past-events-header + .result-container {
      padding-top: 3rem; }
    ._pastEvents .past-events-header h3 {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      padding: 10px 15px;
      margin: 0; }
  ._pastEvents .header h1 a {
    color: #222222; }
    ._pastEvents .header h1 a:hover {
      border-color: #222222; }
  ._pastEvents .month {
    background-color: #ccc !important; }
  ._pastEvents .day {
    color: #888888 !important; }
  ._pastEvents .event-button {
    display: none !important; }

.proximity-finder {
  margin-bottom: 1.5rem; }
  .proximity-finder .use-current-location {
    font-size: 1.125rem;
    margin-top: 1rem;
    line-height: 1.5; }
    .proximity-finder .use-current-location a {
      border-bottom: 1px dashed transparent;
      transition: border-color 300ms ease; }
      .proximity-finder .use-current-location a:hover {
        border-color: #0081c6; }

.location-details {
  display: block;
  width: 100%; }
  .location-details:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 480px) {
    .location-details {
      display: flex; } }
  .location-details .description {
    width: 100%;
    padding-right: 1rem; }
  .location-details .address span {
    display: block; }

.icon-split {
  display: block;
  position: relative;
  line-height: 1.5; }
  .icon-split .text-container {
    display: flex;
    font-family: Arial, Helvetica, sans-serif; }
  .icon-split .mileage {
    margin: 0;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: #000; }

.location-stats {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  margin-top: 1rem;
  float: left; }
  @media screen and (min-width: 480px) {
    .location-stats {
      width: 40%;
      min-width: 200px;
      margin-top: 0; } }
  @media screen and (min-width: 992px) {
    .location-stats {
      width: 30%;
      max-width: 260px; } }
  .location-stats .priority {
    text-align: center;
    font-weight: 700;
    font-size: 1.25rem;
    padding-top: 0.5rem; }
  .location-stats .plus-icon-container {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 1.5rem; }

.save-my-spot {
  padding: 0.25rem;
  padding-top: 0; }
  .save-my-spot.urgent {
    color: #ac208e; }
  .save-my-spot.emergency {
    color: #ed1c24; }
  .save-my-spot .wait {
    text-align: center;
    color: #555555; }
    .save-my-spot .wait span {
      font-weight: bold; }
      @media (max-width: 767px) {
        .save-my-spot .wait span {
          display: block;
          font-size: 1.5rem; } }
  .save-my-spot .saveMySpot-container {
    text-align: center;
    margin-top: 0.5rem; }
    .save-my-spot .saveMySpot-container a {
      border: 1px solid #0081c6;
      display: block;
      width: 100%;
      text-align: center;
      padding: 0.5rem; }

.doctor-wrapper {
  float: left;
  width: 60%; }
  @media (max-width: 767px) {
    .doctor-wrapper {
      width: 100%; } }
  .doctor-wrapper .position {
    line-height: 1.2;
    font-weight: 700;
    margin: 0.25rem 0;
    color: #222222;
    font-family: GothamRounded-Book; }
    .doctor-wrapper .position {
      font-size: 1rem; }
      @media screen and (min-width: 30rem) {
        .doctor-wrapper .position {
          font-size: calc(1rem + 0.125 * ((100vw - 30rem) / 30)); } }
      @media screen and (min-width: 60rem) {
        .doctor-wrapper .position {
          font-size: 1.125rem; } }

.doctor-location-wrapper {
  float: left;
  width: calc(40% - 1rem);
  margin-left: 1rem;
  padding-top: 0.5rem; }
  @media (max-width: 767px) {
    .doctor-location-wrapper {
      width: 100%;
      margin-left: 0; } }
  .doctor-location-wrapper ul {
    margin: 0;
    padding: 0; }
    .doctor-location-wrapper ul li {
      margin: 0;
      list-style-type: none; }

.doctor-locations li {
  position: relative;
  display: none; }
  .doctor-locations li + li {
    margin-top: 1rem; }
  .doctor-locations li.closest {
    display: block; }
  .modal-content .doctor-locations li {
    display: block; }

.doctor-locations .icon {
  position: absolute;
  top: 0;
  left: -1rem; }

.doctor-locations .see-all-locations {
  display: block; }

.network-badges {
  display: block;
  position: absolute;
  bottom: -15px;
  right: 10px; }
  .network-badges .network-badge {
    float: right;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-left: 5px;
    z-index: 100; }

#location-form + .side-nav-facets {
  border-top: 2px solid rgba(0, 0, 0, 0.1); }
  #location-form + .side-nav-facets:after {
    content: "";
    display: table;
    clear: both; }

.side-facets .nav-title,
.center-facets .nav-title {
  padding: 0; }

.side-facets .radio-container [type=radio],
.center-facets .radio-container [type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .side-facets .radio-container [type=radio] + span:before,
  .center-facets .radio-container [type=radio] + span:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 1em;
    box-shadow: 0 0 0 2px #666;
    vertical-align: top;
    margin: 3px 10px 0 0; }
    @media (min-width: 992px) {
      .side-facets .radio-container [type=radio] + span:before,
      .center-facets .radio-container [type=radio] + span:before {
        width: 10px;
        height: 10px;
        margin: 3px 5% 0 0;
        box-shadow: 0 0 0 1.5px #666; } }
  .side-facets .radio-container [type=radio]:checked + span:before,
  .center-facets .radio-container [type=radio]:checked + span:before {
    background: #00a94f;
    box-shadow: 0 0 0 2px #666; }

.side-facets .filter-options div.filter-see-all .filter-see-all-toggle,
.center-facets .filter-options div.filter-see-all .filter-see-all-toggle {
  position: relative;
  padding-left: 30px; }
  .side-facets .filter-options div.filter-see-all .filter-see-all-toggle p, .side-facets .filter-options div.filter-see-all .filter-see-all-toggle i,
  .center-facets .filter-options div.filter-see-all .filter-see-all-toggle p,
  .center-facets .filter-options div.filter-see-all .filter-see-all-toggle i {
    color: #0081c6; }
  .side-facets .filter-options div.filter-see-all .filter-see-all-toggle i,
  .center-facets .filter-options div.filter-see-all .filter-see-all-toggle i {
    position: absolute;
    left: 0;
    top: 0.125rem; }

.side-facets .filter-options ul li input,
.center-facets .filter-options ul li input {
  font-size: 1em;
  position: absolute; }

.side-facets .filter-options ul li input[type=checkbox] + label:before,
.center-facets .filter-options ul li input[type=checkbox] + label:before {
  width: 30px; }

.date-dropdown select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%; }
  .date-dropdown select option {
    padding-left: 1rem;
    font-size: 1rem; }
    .date-dropdown select option.year {
      background-color: rgba(0, 0, 0, 0.05);
      padding-left: 0;
      font-weight: 700;
      font-size: 1.125rem; }
    .date-dropdown select option.month {
      padding-left: 1rem;
      font-size: 1rem; }

.date-dropdown .form-control {
  height: auto; }

.date-dropdown .ui-selectmenu-button.ui-button {
  display: block;
  width: 100%;
  line-height: 24px;
  background: #fff;
  border-top-left-radius: none !important; }

.ui-selectmenu-menu {
  z-index: 1100;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.6); }
  .ui-selectmenu-menu .ui-widget-content {
    padding: 10px; }
  .ui-selectmenu-menu.ui-front {
    z-index: 1100; }

.ui-selectmenu-optgroup ~ .ui-menu-item .ui-menu-item-wrapper {
  padding-left: 1rem; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active, .ui-button:active,
.ui-button.ui-state-active:hover {
  border-width: 1px;
  background: rgba(0, 0, 0, 0.05);
  font-weight: normal;
  color: #212121; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: none; }

.ui-selectmenu-button-open .ui-selectmenu-icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg); }

.side-facets {
  display: block; }
  @media screen and (min-width: 992px) {
    .side-facets {
      width: 30%;
      float: left;
      padding: 1.5rem 0.75rem; } }
  .side-facets form {
    margin-bottom: 1rem; }

.center-facets {
  position: relative;
  max-height: 0;
  margin: 0 -0.75rem;
  overflow: hidden;
  transition: max-height 0.75s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05) inset; }
  .center-facets #facets {
    position: relative;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding-bottom: 1.5rem;
    margin-bottom: 1rem; }
  .center-facets .filter-submit {
    max-width: unset !important; }
  .center-facets.open {
    max-height: 9999px; }
  .center-facets .close-facets {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    bottom: -1rem;
    z-index: 1000; }
    .center-facets .close-facets button {
      background-color: #0081c6;
      color: #fff;
      padding: 0.25rem 0.5rem;
      border: 1px solid #0081c6;
      border-radius: 4px;
      text-transform: uppercase;
      transition: all 100ms ease; }
      .center-facets .close-facets button:hover {
        background-color: #006093; }

.close-facets {
  display: none; }

#facets {
  display: none; }
  @media screen and (min-width: 992px) {
    #facets {
      display: block; } }

.facet-graphic-list label {
  min-height: 32px; }
  .facet-graphic-list label .facet-graphic-container {
    position: absolute;
    top: -6px;
    left: 24px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 4px; }
  .facet-graphic-list label p {
    padding-left: 30px; }

.search-results {
  display: block;
  padding: 1.5rem 0.75rem; }
  @media screen and (min-width: 992px) {
    .search-results {
      width: 70%;
      float: left;
      padding-right: 1.5rem; } }

.search-results .input-group ::-ms-clear,
.side-facets .input-group ::-ms-clear {
  width: 0;
  height: 0; }

.search-results .input-group .fa-remove:before,
.search-results .input-group .fa-close:before,
.search-results .input-group .fa-times:before,
.side-facets .input-group .fa-remove:before,
.side-facets .input-group .fa-close:before,
.side-facets .input-group .fa-times:before {
  content: "\f00d"; }

.nav-tabs {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 767px) {
  .nav-tabs.search {
    margin: 0 -15px;
    font-size: 0.7rem; } }

.facet-disclaimer, .specialty-disclaimer {
  font-size: 0.865rem;
  font-style: italic;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 1rem; }

.specialty-disclaimer {
  margin-top: 0;
  margin-bottom: 1rem; }

.side-nav-facets .nav + .nav {
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.side-nav-facets > .nav {
  overflow: hidden;
  margin: 0; }
  .side-nav-facets > .nav li:not(.nav-item) {
    position: relative;
    padding-left: 30px; }
    .side-nav-facets > .nav li:not(.nav-item) :before {
      position: absolute;
      left: 0; }
    .side-nav-facets > .nav li:not(.nav-item) p {
      width: 100% !important; }
  .side-nav-facets > .nav .nav-item {
    background-color: transparent; }
    .side-nav-facets > .nav .nav-item .nav-title {
      background-color: transparent;
      color: #00a94f;
      font-family: GothamRounded-Book;
      font-size: 1.25rem !important;
      font-weight: bold;
      width: 100%;
      line-height: 1.25;
      padding: 0.25rem 0; }
    .side-nav-facets > .nav .nav-item .nav-arrow {
      background-color: transparent;
      padding: 1rem; }
      .side-nav-facets > .nav .nav-item .nav-arrow:before {
        color: #0081c6; }

.search-input-group {
  position: relative;
  display: flex;
  font-size: 1rem; }
  .search-input-group .form-control {
    height: auto; }
  @media screen and (max-width: 992px) {
    .search-input-group.primary .input-group {
      width: calc(100% - 60px) !important; } }
  .search-input-group .input-group {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.25);
    background-color: #fff; }
    .search-input-group .input-group input {
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 300;
      padding: 1rem 1.5rem;
      padding-right: 40px; }
  .search-input-group .search-submit {
    height: 100%; }
  .search-input-group .filter-button {
    display: block;
    background-color: transparent;
    margin-left: 10px;
    width: 50px;
    height: 65px;
    background-color: #0081c6;
    color: #fff;
    padding: 0.25rem 0.5rem;
    border: 1px solid #0081c6;
    border-radius: 4px;
    text-transform: uppercase;
    transition: all 100ms ease; }
    @media screen and (min-width: 992px) {
      .search-input-group .filter-button {
        display: none; } }
    .search-input-group .filter-button:hover {
      background-color: #006093; }
  .search-input-group .input-group-btn {
    width: 50px; }
  .search-input-group button[type=reset] {
    display: block;
    position: absolute;
    right: 50px;
    width: 50px;
    height: 100%;
    z-index: 10;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.25); }
    .search-input-group button[type=reset]:hover {
      color: rgba(0, 0, 0, 0.5); }
    .search-input-group button[type=reset] i:before {
      font-size: 1rem; }
  .search-input-group button[type=submit] {
    border-left: 1px solid #ddd;
    font-size: 1rem;
    transition: color 300ms ease;
    color: #0081c6; }
    .search-input-group button[type=submit]:hover {
      color: #00a2f9; }

input:not(:valid) ~ button[type=reset] {
  display: none; }

.modal-search .search-suggestions {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1100;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.6); }
  .modal-search .search-suggestions:empty {
    padding: 0 !important;
    border: 0 !important; }
  @media screen and (min-width: 992px) {
    .modal-search .search-suggestions {
      max-height: 50vh;
      overflow-y: scroll; } }
  @media screen and (max-height: 600px) and (orientation: landscape) {
    .modal-search .search-suggestions {
      max-height: 50vh;
      overflow-y: scroll; } }
  .modal-search .search-suggestions::-webkit-scrollbar {
    width: 40px; }
  .modal-search .search-suggestions::-webkit-scrollbar-track-piece {
    background-color: #FFF; }
  .modal-search .search-suggestions::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    outline: 2px solid #FFF;
    outline-offset: -2px;
    border: 12px solid transparent; }
  .modal-search .search-suggestions::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.25); }
  .modal-search .search-suggestions ul {
    padding: 0;
    margin: 1rem 0; }
    .modal-search .search-suggestions ul li {
      list-style-type: none;
      padding: 0.25rem 1rem; }
  .modal-search .search-suggestions .ui-autocomplete-term {
    font-weight: bold;
    color: #003f60; }
  .modal-search .search-suggestions .ui-autocomplete-category {
    font-weight: bold;
    font-family: GothamRounded-Book;
    color: #000;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    margin-top: 1rem; }
    .modal-search .search-suggestions .ui-autocomplete-category {
      font-size: 1rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .search-suggestions .ui-autocomplete-category {
          font-size: calc(1rem + 0.125 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .search-suggestions .ui-autocomplete-category {
          font-size: 1.125rem; } }
    .modal-search .search-suggestions .ui-autocomplete-category:first-of-type {
      border: none;
      margin-top: 0; }
  .modal-search .search-suggestions .ui-menu-item {
    font-family: GothamRounded-Book;
    transition: all 300ms ease; }
    .modal-search .search-suggestions .ui-menu-item {
      font-size: 1rem; }
      @media screen and (min-width: 20rem) {
        .modal-search .search-suggestions .ui-menu-item {
          font-size: calc(1rem + 0.5 * ((100vw - 20rem) / 55)); } }
      @media screen and (min-width: 75rem) {
        .modal-search .search-suggestions .ui-menu-item {
          font-size: 1.5rem; } }
    .modal-search .search-suggestions .ui-menu-item span {
      color: #999; }
      .modal-search .search-suggestions .ui-menu-item span {
        font-size: 1rem; }
        @media screen and (min-width: 20rem) {
          .modal-search .search-suggestions .ui-menu-item span {
            font-size: calc(1rem + 0.25 * ((100vw - 20rem) / 55)); } }
        @media screen and (min-width: 75rem) {
          .modal-search .search-suggestions .ui-menu-item span {
            font-size: 1.25rem; } }
    .modal-search .search-suggestions .ui-menu-item a {
      color: #0081c6;
      font-weight: bold; }
      .modal-search .search-suggestions .ui-menu-item a b {
        color: #003f60;
        font-weight: 900; }
      .modal-search .search-suggestions .ui-menu-item a:hover {
        background-color: transparent;
        color: #00a2f9; }
    .modal-search .search-suggestions .ui-menu-item .badge {
      color: #333; }
      .modal-search .search-suggestions .ui-menu-item .badge.count {
        background-color: transparent; }
        .modal-search .search-suggestions .ui-menu-item .badge.count:before {
          content: "(";
          color: rgba(0, 0, 0, 0.25);
          margin-right: 2px; }
        .modal-search .search-suggestions .ui-menu-item .badge.count:after {
          content: ")";
          color: rgba(0, 0, 0, 0.25);
          margin-left: 2px; }

.sort-container {
  position: relative;
  min-height: 1.5rem;
  margin: 0.25rem 0; }

.sort {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .sort li {
    display: inline; }
  .sort .arrow {
    display: none;
    width: 0;
    height: 0; }
    .sort .arrow.down {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #0081c6; }
  .sort .sort-option {
    position: relative;
    padding: 0 0.25rem;
    cursor: pointer; }
    .sort .sort-option.active {
      font-weight: bold; }
      .sort .sort-option.active .arrow {
        display: block;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%); }
    .sort .sort-option + .sort-option {
      border-left: 1px solid rgba(0, 0, 0, 0.1); }

.results {
  position: absolute;
  right: 0;
  top: 0;
  color: #888888; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }
