@import "../base/mixins.scss";
@import "../base/variables.scss";


////////////////////////////////////////////
// SEARCH RESULTS PAGE OPTIONS
////////////////////////////////////////////


// more results
.more-results {
  .fa {
    font-weight: 900;
    margin-right:10px;
  }
  margin-bottom:1rem;
}

a.link {
  span {
    border-bottom:1px dashed transparent;
    transition:all 300ms ease;
  }
  &:hover {
    span {
      border-color:$choa-blue;
    }
  }
}

// tokenized facets
.tokens {
  display: block;
  margin-bottom:1.5rem;
  margin-top:1rem;
}
.token {
  position:relative;
  display: inline-block;
  border:1px solid $black25;
  background-color:white;
  border-radius:$br-sm;
  padding:0.25rem 0.5rem;
  padding-right:1.5rem;
  margin-right:0.5rem;
  margin-bottom:0.25rem;
  box-shadow:1px 4px 2px -2px $black10;
  .token-label {

  }
  .close {
    @include ctr(y);
    right:5px;
    min-height:1.5rem;
    color:$text-color-light;
    transition:all 300ms ease;
    //text-transform: uppercase;
    font-size: 24px;
    &:hover {
      color:$text-color-dark;
    }
    &:active:hover {
      border-color:red;
    }
  }
}




.filter-options .radio-container [type=radio]+span:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 1em;
  box-shadow: 0 0 0 2px #666;
  vertical-align: top;
  margin: 3px 5% 0 0;
  @media (min-width: 992px) {
    width: 10px;
    height: 10px;
    margin: 3px 5% 0 0;
    box-shadow: 0 0 0 1.5px #666;
  }  
}

.filter-see-all-toggle {
  position: relative;
  margin-top:1rem;
  i {
    float:left;
  }
  i,p {
    color:$choa-blue !important;
    font-weight: 700;
  }
  &:hover {
    i {
      animation: zoom 300ms ease 3;
    }
  }
}

@keyframes zoom {
  0% {transform:scale(1);}
  50% {transform:scale(1.2);}
  100% {transform:scale(1);}
}


.search-alternate {
  padding:1rem 0;
  font-size: 1.25rem;
  a {
    @include link-blue-underline;        
  }  
  &:empty {
    padding:0 !important;
  }
}

////////////////////////////////////////////
// CONTENT TYPE FORM FACTORS
////////////////////////////////////////////


.result-container {
  font-size:18px;
  width:100%;
  position: relative;
  padding-top:1.5rem;
  padding-bottom:1rem;
  line-height: 1.5;
	&:after {
		content: "";
		display: table;
		clear: both;
	}

  .doctor-image {
    position: relative;
    width:160px;
    margin-right:1rem;
	  margin-bottom:0;
    + .result-summary {
      width:calc(100% - 160px - 1rem);
    }    
    @include mobile {
      margin:0;
      margin-top:0.5rem;
      margin-right:1rem;
      width:85px;
      height: 120px;
      min-width: unset;
      max-width: unset;
      .image-wrapper {
        width:85px !important;
        height: 120px;
      }
      + .result-summary {
        width:calc(100% - 85px - 1rem);
      }   
    }
  }  
  a {
    @include link-blue-underline;         
  }  
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  + .result-container {
    border-top:2px solid $black05;
  }



}

.result-container,
.appointment-title,
.spec-wrapper,
.specs {
  h4 {
    font-weight: 700;
    font-size:0.75rem;
    color:$text-color-dark;
    line-height:1.2;
    margin-top:1rem; 
    margin-bottom:0;
  }
}

// no results error
.error-wrapper {
  display: block;
  width:100%;
  padding:1.5rem 1rem;
  .no-results {
    text-align: center;
    width:100%;
    font-size: 1.25rem;
    span {
      color: $choa-red;
      font-style:italic;
    }
  }
}


.result-icon {
  width:100px;
  float: left;
  @media screen and (min-width:$bp-md){
    width:120px;
  }
  + .result-summary {
    width:calc(100% - 100px);
    @media screen and (min-width:$bp-md){
      width:calc(100% - 120px);
    }
  }
}

.result-summary {
  float: left;
  width:100%;;
  .date {
    font-size:0.75rem;
  }
  button {
	margin-bottom:1rem;
  }
  .header {
    h1 {
      margin:0.5rem 0;
      line-height: 1.365; 
      @include mobile {
        line-height: 1.365; 
      }  
      @include fluid-type(30rem,60rem,1.365rem,1.625rem);     
      &.name {
        margin-bottom:0;
      }
      a {
        @include link-blue-underline;         
      }
    }
    .service & {
      h1 {
        margin-top:0.5rem;
        margin-bottom:1rem;
        @include fluid-type(30rem,60rem,1.5rem,2rem);
      }
    }
	&:first-child h1 {
		margin-top:0;
	}
  }

  .datetime {
    color:$text-color-dark;
    font-weight: bold;
    
    margin-bottom:1rem;
    .date {
      display: block;
      font-size: 1.125rem;
    }
    .time {
      display: block;
      font-size: 1.125rem;
    }
  }
  .description {
    line-height:1.5;
  }
  .richtext-content {
    font-size:1rem;
    @media screen and (min-width:$bp-sm){
      font-size:1em;
    }
  }
  .cite {
    position: relative;
    display:block;
    font-style: italic;
    color: $text-color-light;
    margin-bottom:1rem;
    font-size:1rem;
    &::after {
      position: absolute;
      content:'';
      top:-0.5rem;
      left:0;
      height:1px;
      width:40px;
      z-index: 1000;
      background-color:$black25;
    }
  }
}


.event-button,
.saveMySpot-container a {
  position: relative;
  display: block;
  border: 1px solid $choa-blue;
  background-color: #fff;
  padding: 0.5rem 1rem;

  text-align: center;
  color: $choa-blue;  
  font-weight: bold;    
  border-radius:$br-sm;
  box-sizing:border-box;
  
  transition: 
    border 300ms, 
    color 300ms 300ms,
    text-shadow 300ms 300ms,
    background-color 300ms 150ms; 

  .fa {
    margin-right:0.5rem;
    position: absolute;
    left:1rem;
    @include ctr(y);
    + span {
      padding-left:1.5rem
    }
  }

  &:hover {
    background-color:$choa-blue;
    color:#fff;
    text-shadow: 0 1px 2px $black50;
    border:1px solid darken($choa-blue, 10%);
    &:before,
    &:after {
      width:100%;
      transition-delay: 0s;
    }
    //span {
    //  &:before,
    //  &:after {
    //    height:100%;
    //    transition-delay: 0.1s;
    //  }
    //}
  }

  &:before {
    right: 0;
    top: 0;
  }
  &:after {
    left: 0;
    bottom: 0;
  }
  &::before, 
  &::after{
    content:"";
    width: 0;
    height: 2px;
    position: absolute;
    transition: all 0.2s linear;
    background: $choa-dkblue;
    //transition-delay: 0.1s;
  }  

  //span{
  //  display: block;
  //  &:before {
  //    left: 0;
  //    top: 0;
  //  }
  //  &:after {
  //    right: 0;
  //    bottom: 0;
  //  }
  //  &::before, 
  //  &::after{
  //    content:"";
  //    width:2px;
  //    height:0;
  //    position: absolute;
  //    transition: all 0.2s linear;
  //    background: $choa-dkblue;
  //    transition-delay: 0s;
  //  }    
  //}
    
}



.graphic-container {
  display: block;
  width:80px;
  height:95px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
  @media screen and (min-width:$bp-md){
    width:100px;
    height:120px;
  }

  // Events
  &.calendar {
    padding:10px;
    padding-top:20px;
    background-image: url('../images/graphic-calendar.png');    
    width:80px;
    height:120px;    
    @media screen and (min-width:$bp-md){
      width:100px;
      height:145px;
    }    
    .month {
      display: block;
      text-align:center;
      background-color: $choa-green;
      color:#fff;
      font-size: 14px;
      padding:0.25rem 0;
      font-weight: 900;
      margin-bottom:0.25rem;
      text-transform: uppercase;
    }
    .day {
      text-align:center;
      color:$text-color-dark;
      font-size: 24px;
      line-height: 1;
      font-weight: 900;
      margin-bottom:0.25rem;
    }
    .weekday {
      text-align:center;
      color:$text-color-light;
      text-transform: uppercase;
      font-size: 10px;
    }
    @media screen and (min-width:$bp-md){
      .month { font-size: 20px; }
      .day { font-size: 40px; }
      .weekday { font-size: 12px; }
    }  
  }

  // News
  &.news {
    padding:10px;
    background-image: url('../images/graphic-news.png');
    .title {
      color:#fff;
      text-transform:uppercase;
      font-size:0.75rem;
      width:100%;
      text-align:center;
      padding-top:5px;
      padding-left:14px;
      @media screen and (min-width:$bp-md){
        padding-top:12px;
      }
    }
  }

  // Files
  &.file {
    padding:10px;
    padding-top:20px;
    background-image: url('../images/graphic-file.png');
    position: relative;
    .type {
      position: absolute;
      right:0;
      top:45px;      
      text-align:center;
      color:#fff;
      text-transform:uppercase;
      padding:0.125rem 0.5rem;
      @media screen and (min-width:$bp-md){
        top:60px;
      }
      .pdf & {
        background-color:$choa-red;
        &:after {
          content:"PDF";
        }
      }
      .ppt & {
        background-color:$choa-red;
        &:after {
          content:"PPT";
        }
      }
      .doc & {
        background-color:$choa-blue;
        &:after {
          content:"DOC";
        }
      }
      .xls & {
        background-color:$choa-green;
        &:after {
          content:"XLS";
        }
      }
    }
  }
  
  // Stories
  &.story {
    padding:10px;
    padding-top:20px;
    background-image: url('../images/graphic-story.png');
  }  
}


// Past Events

._pastEvents {
  background: #f0efec;
  margin:0 -15px;
  padding:0 15px; 
  position: relative;
  &:first-of-type {
    margin-top:2rem; 
    .result-container {
      padding-top:2rem;
    }
  }
  &:last-of-type {
    @include linear-gradient(#f0efec,transparent);
  }
  .past-events-header {
    background-color: $choa-green;
    @include ctr(x);
    top:-1.25rem;
	+.result-container {
		padding-top:3rem;
	}
    h3 {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      padding: 10px 15px;
      margin:0;
	  }
  }  
  .header {
    h1 a {
      color:$text-color-dark;
      &:hover {
        border-color:$text-color-dark;
      }
    }
  }  
  .month {
    background-color: #ccc !important;
  }
  .day {
    color: $text-color-light !important;  
  }
  .event-button {
    display:none !important;
  }
}




// Proximity 

.proximity-finder {
  margin-bottom:1.5rem;
  .use-current-location {
    font-size:1.125rem;
    margin-top:1rem;
    line-height:1.5;
    a {
      border-bottom:1px dashed transparent;
      transition:border-color 300ms ease;
      &:hover {
        border-color:$choa-blue;
      }
    }
  }
}



//Locations

.location-details {
  display:block;
  width:100%;
	&:after {
		content: "";
		display: table;
		clear: both;
  }  
  @media screen and (min-width:$bp-xs) {
    display: flex;
  }
  .description {
    width:100%;
    padding-right:1rem;
  }  
  .address {
    span {
      display: block;
    }
  }
  //a {
  //  border-bottom:1px dashed transparent;
  //  transition:border-color 300ms ease;
  //  &:hover {
  //    border-color:$choa-blue;
  //  }        
  //}  
}

.icon-split {
  display: block;
  position:relative;
  line-height: 1.5;
  
  .text-container {
    display:flex;
    font-family: Arial, Helvetica, sans-serif;
  }
  .mileage {
    margin:0;
    padding-left:0.5rem;
    margin-left:0.5rem;
    border-left:1px solid $black10;
    color:#000;
  }
}

.location-stats {
  position: relative;
  border: $border;
  border-radius: $br-sm;
  width:100%;
  margin-top:1rem;
  
  @media screen and (min-width:$bp-xs){
    width: 40%;
    min-width: 200px;
    margin-top:0;
  }  
  @media screen and (min-width:$bp-md){
    width: 30%;
    max-width: 260px;
  }     
  float:left;
  .priority {
    text-align: center;
    font-weight: 700;
    font-size: 1.25rem;
    padding-top:0.5rem;
  }
  .plus-icon-container {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size:1.5rem;
  }  
}

.save-my-spot {
  padding:0.25rem;
  padding-top:0;
  &.urgent {
    color: $choa-magenta;
  }
  &.emergency {
    color: $choa-red;
  }  
  .wait {
    text-align: center;
    color: $text-color;
    span {
      font-weight: bold;
      @include mobile{
        display: block;
        font-size: 1.5rem;
      }
    }
  }
  .saveMySpot-container {
    text-align: center;
    margin-top:0.5rem;
    a {
      border:1px solid $choa-blue;
      display:block;
      width:100%;
      text-align:center;
      padding:0.5rem;
    }
  }  
}




// Doctors

.doctor-wrapper {
  float:left;
  width:60%;
  @include mobile {
    width:100%;
  }
  .position {
    @include fluid-type(30rem,60rem,1rem,1.125rem);
    line-height:1.2;
    font-weight: 700;
    margin:0.25rem 0; 
    color:$text-color-dark;
    font-family: $gotham;
  }
}
.doctor-location-wrapper {
  float:left;
  width:calc(40% - 1rem);
  margin-left:1rem;
  padding-top:0.5rem;
  @include mobile {
    width:100%;
    margin-left:0;
  }
  ul {
    margin:0; padding:0;
    li {
      margin:0;
      list-style-type: none;
    }
  }  
}
.doctor-locations {
  li {
    position:relative;
    display: none;
    + li {
      margin-top:1rem;
    }
    &.closest {
      display:block;
    }    
    .modal-content & {
      display:block;
    }
  }
  .icon {
    position:absolute;
    top:0;
    left:-1rem;
  }
  .see-all-locations {
    display: block;
  }
}

.network-badges {
  display: block;
  position: absolute;
  bottom:-15px;
  right:10px;
  .network-badge {
    float:right;
    width:30px;
    height:30px;
    border-radius:$br-sm;
    margin-left:5px;
    z-index: 100;
  }
}

#location-form + .side-nav-facets {
	border-top:2px solid $black10;
		&:after {
		content: "";
		display: table;
		clear: both;
	}
}
