@import "../base/mixins.scss";
@import "../base/variables.scss";

.side-facets,
.center-facets {
  // radio button percentage margin override
  .nav-title {
    padding:0;
  }
  .radio-container [type=radio] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    + span {
      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 1em;
        box-shadow: 0 0 0 2px #666;
        vertical-align: top;    
        margin:3px 10px 0 0;
        @media (min-width: 992px) {
          width: 10px;
          height: 10px;
          margin: 3px 5% 0 0;
          box-shadow: 0 0 0 1.5px #666;
        }      
      }
    }
    &:checked {
      + span {
        &:before {
          background: #00a94f;
          box-shadow: 0 0 0 2px #666;
        }
      }
    }
  }  
  .filter-options {
    div.filter-see-all {
      // see all button styling override
      .filter-see-all-toggle {
        position:relative;
        padding-left:30px;
        p,i {
          color:$choa-blue;
        }
        i {
          position: absolute;
          left:0;
          top:0.125rem;
        }
      }
    }
    // checkbox margin override
    ul {
      li {
        input {
          font-size: 1em;
          position: absolute;
        }
        input[type=checkbox]+label:before {
          width: 30px;
        }
      }
    }
  }
}


.date-dropdown {
  select {
    border:1px solid $black10;
    width:100%;
    option {
      padding-left:1rem;
      font-size:1rem;
      &.year {
        background-color:$black05;
        padding-left:0;
        font-weight: $bold;
        font-size:1.125rem;
      }
      &.month {
        padding-left:1rem;
        font-size:1rem;
      }
    }
  }
  .form-control {
    height: auto;
  }
  .ui-selectmenu-button.ui-button {
    display:block;
    width:100%;
    line-height: 24px;
    background:#fff;
    border-top-left-radius: none !important;
  }


}
.ui-selectmenu-menu {
  z-index:$z-index-modal-dropdown;
  box-shadow: $shadow-md;
  .ui-widget-content {
    padding:10px;
  }
  &.ui-front {
	z-index:$z-index-modal-dropdown;
  }
}
.ui-selectmenu-optgroup {
  ~ .ui-menu-item .ui-menu-item-wrapper {
    padding-left:1rem;
  }
}
.ui-state-active, 
.ui-widget-content .ui-state-active, 
.ui-widget-header .ui-state-active, 
a.ui-button:active, .ui-button:active, 
.ui-button.ui-state-active:hover {
  border-width:1px;
  background: $black05;
  font-weight: normal;
  color: #212121;
}

.ui-state-active, 
.ui-widget-content .ui-state-active, 
.ui-widget-header .ui-state-active {
  border:none;
}

.ui-selectmenu-button-open {
  .ui-selectmenu-icon {
    transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
  }
}


.side-facets {
  display: block;
  @media screen and (min-width:$bp-md){
      width:30%;
      float:left;
      padding:1.5rem 0.75rem;
  }
  form {
    margin-bottom:1rem;
  }
}

.center-facets {
  position:relative;
  max-height:0;
  margin:0 -0.75rem;  
  overflow:hidden;
  transition:max-height 0.75s ease;
  box-shadow:0 4px 8px $black05 inset;
  #facets{
    position:relative;
    background-color:$black05;
    padding:1rem;
    border-bottom:1px solid $black25;
    padding-bottom:1.5rem;
    margin-bottom:1rem;
  }
  .filter-submit {
    max-width: unset !important;
  }
  &.open {
    max-height:9999px;
  }
  .close-facets {
    @include ctr(x);
    display: block;
    bottom:-1rem;
    z-index: $z-index-panel-close;
    button {
      @include btn-blue;
    }
  }
}


.close-facets {
  display: none;
}

#facets {
  display: none;
  @media screen and (min-width:$bp-md){
    display: block;
  }
}

// for facet lists with icons
.facet-graphic-list {
  label {
    min-height:32px;
    .facet-graphic-container {
      position:absolute;
      top:-6px;
      left:24px;
      width:30px;
      height:30px;
      text-align: center;
      border-radius:$br-sm;
    }
    p {
      padding-left:30px;
    }
  }
}


.search-results {
  display: block;
  padding:1.5rem 0.75rem;
  @media screen and (min-width:$bp-md){
      width: 70%;
      float:left;
      padding-right:1.5rem;
  } 
}

.search-results,
.side-facets {
	.input-group {
		::-ms-clear {
			width : 0;
			height: 0;
		}
		.fa-remove:before, 
		.fa-close:before, 
		.fa-times:before {
			content: "\f00d";
		}
	}
}

.nav-tabs {
  border-bottom:2px solid $black10;
}

.nav-tabs.search {
  @media screen and (max-width:$bp-sm - 1){
    margin:0 -15px;
	font-size: 0.7rem;
  }
}


.facet-disclaimer, .specialty-disclaimer {
  font-size: 0.865rem;
  font-style: italic;
  font-weight:normal;
  line-height: 1.5;
  margin-top:1rem; 
} 
.specialty-disclaimer {
  margin-top: 0;
  margin-bottom: 1rem;
}


.side-nav-facets {
  .nav + .nav {
    border-top:2px solid $black10;
  }
  > .nav {
      overflow: hidden; 
	  margin:0;
      //overflow-y: scroll; 
     //max-height: 60vh;
     li:not(.nav-item) {
       position:relative;
       padding-left:30px;
       :before {
        position:absolute;
        left:0;
       }
       p {
         width:100% !important;
       }
     }
    .nav-item {
      background-color:transparent;
      .nav-title {
        background-color:transparent;
        color:$choa-green;
        font-family: $gotham;
        font-size: 1.25rem !important;
        font-weight: bold;
        width:100%;
        line-height: 1.25;
        padding:0.25rem 0;
      }
      .nav-arrow {
        background-color:transparent;
        padding: 1rem;
        &:before {
            color:$choa-blue;
        }
      }
    }
  }
}    


.search-input-group {
  position: relative;
  display: flex;
  font-size:1rem;
  .form-control {
    height: auto;
  }
  &.primary {
    @media screen and (max-width:$bp-md){
      .input-group {
        width:calc(100% - 60px) !important;
      }
    }
  }
  .input-group {
    width:100%;
    border:2px solid $black25;
    background-color:#fff;
    input {
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 300;
      padding:1rem 1.5rem;
      padding-right:40px;
    }
  }
  .search-submit {
    height: 100%;
  }
  .filter-button {
    display:block;
    background-color:transparent;
    margin-left:10px;
    width:50px;
    height:65px;      
    @media screen and (min-width:$bp-md){        
      display:none;  
    }
    @include btn-blue;
  }
  .input-group-btn {
    width:50px;
  }

  button[type=reset]{
    display: block;
    position: absolute;
    right:50px;
    width:50px;
    height:100%;
    z-index: 10;
    background-color:transparent;
    color:$black25;
    &:hover {
      color:$black50;
    }
    i {
      &:before {
        font-size:1rem;
      }
    }
  }
  button[type=submit]{
    border-left:1px solid #ddd;
    font-size:1rem;
    @include link-blue;
  }
}


input:not(:valid) {
  ~ button[type=reset] {
    display: none;
  }
}



// jquery autocomplete styles
.modal-search .search-suggestions {
  display: block; 
  margin:0 auto;
  position: relative;
  width:100%;
  z-index: $z-index-modal-dropdown;
  background-color:#fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.6);
  &:empty {
    padding:0 !important;
    border:0 !important;
  }
  @media screen and (min-width:$bp-md){
    max-height:50vh;
    overflow-y:scroll;    
  }
  @media screen and (max-height:600px) and (orientation:landscape){
    max-height:50vh;
    overflow-y:scroll;       
  }
  
  // minimal scrollbars
  &::-webkit-scrollbar{
    width: 40px;
  }
  &::-webkit-scrollbar-track-piece{
    background-color: #FFF;
  }
  &::-webkit-scrollbar-thumb{
    background-color: $black10;
    outline: 2px solid #FFF;
    outline-offset: -2px;
    border: 12px solid transparent;
  }
  &::-webkit-scrollbar-thumb:hover{
    background-color: $black25;
  }
  ul {
    padding:0;
    margin:1rem 0;
    li {
      list-style-type: none;
      padding:0.25rem 1rem;
    }
  }
  .ui-autocomplete-term { 
    font-weight: bold; 
    color: darken($choa-blue,20%); 
  }
  
  .ui-autocomplete-category {
    font-weight: bold;
    font-family: $gotham;
    @include fluid-type($min-width, $max-width, 1rem, 1.125rem);
    color:#000;
    border-top:1px solid $black10;
    padding-top:1rem;
    margin-top:1rem;
    &:first-of-type {
      border:none;
      margin-top:0;
    }
  }
  .ui-menu-item {
    font-family: $gotham;   
    @include fluid-type($min-width, $max-width, 1rem, 1.5rem);
    transition:all 300ms ease;
     
    span {
      color:#999;
      @include fluid-type($min-width, $max-width, 1rem, 1.25rem);
    }
    a {
      color:$choa-blue;
      font-weight: bold; 
      b {
        color:darken($choa-blue,20%);
        font-weight: 900;
      }  
      &:hover {
        background-color: transparent;
        color:lighten($choa-blue, 10%);
        b {
          //color:$choa-red;
        }
      }
    }     
    .badge {
      color:#333;
      &.count {
        background-color:transparent;
        &:before {
          content:"(";
          color:$black25;
          margin-right:2px;
        }
        &:after {
          content:")";
          color:$black25;
          margin-left:2px;
        }
      }
    }
  }
}



.sort-container {
  position: relative;
  min-height:1.5rem;
  margin:0.25rem 0;
}
.sort {
  position: relative;
  margin:0;
  padding:0;
  list-style-type: none;
  li {
    display: inline;
  }
  .sort-label {

  }
  .arrow {
    display: none;
    width: 0; 
    height: 0; 
    &.down {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $choa-blue;
    }
  }
  .sort-option {
    position:relative;
    padding:0 0.25rem;
    cursor:pointer;
    &.active {
      font-weight:bold;
      .arrow {
        display: block;
        @include ctr(x);
      }
    }
    + .sort-option {
      border-left:1px solid $black10;
    }
  }
}
.results {
  position: absolute;
  right:0;
  top:0;
  color:$text-color-light;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}