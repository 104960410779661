
//=========================================
// COLORS 
//=========================================

// choa color palette
$choa-green: #00a94f;
$choa-ltblue: #4bbbeb;
$choa-blue: #0081c6;
$choa-dkblue: #005da4;
$choa-red: #ed1c24;
$choa-yellow: #ffdd00;
$choa-gold: #f6b221;
$choa-magenta: #ac208e;

$choa-colors: (
    red: $choa-red,
    gold: $choa-gold,
    yellow: $choa-yellow,
    green: $choa-green,
    lightblue: $choa-ltblue,
    blue: $choa-blue,
    darkblue: $choa-dkblue
) !default;

$text-color:#555555;
$text-color-dark: darken($text-color,20%);
$text-color-light: lighten($text-color,20%);

// transparencies
$black05:rgba(0,0,0,0.05);
$black10:rgba(0,0,0,0.1);
$black25:rgba(0,0,0,0.25);
$black50:rgba(0,0,0,0.5);
$black75:rgba(0,0,0,0.75);
$black90:rgba(0,0,0,0.9);

$white10:rgba(255,255,255,0.1);
$white25:rgba(255,255,255,0.25);
$white50:rgba(255,255,255,0.5);
$white75:rgba(255,255,255,0.75);
$white90:rgba(255,255,255,0.9);


//=========================================
// BREAKPOINTS
//=========================================

$bp-xs:480px;
$bp-sm:768px;
$bp-md:992px;
$bp-lg:1200px;
$bp-lg:1400px;

//=========================================
// TYPOGRAPHY
//=========================================

// fluid-type limits
//320px
$min_width: 20rem;
//1200px
$max_width: 75rem;
$min_font: 1rem;
$max_font: 3rem;

// fonts
//$nunito: "Nunito",Arial, Helvetica, sans-serif; 
$gotham: GothamRounded-Book;

// weights
$extra-light:100;
$light:300;
$regular:400;
$semi-bold:600;
$bold:700;
$extra-bold:900;

//=========================================
// BORDERS
//=========================================

$border-radius:2px;
$br-xs: $border-radius;
$br-sm: $border-radius * 2; // buttons
$br-md: $border-radius * 4;
$br-lg: $border-radius * 6;
$br-xl: $border-radius * 8;

$border: 1px solid $black10;


//=========================================
// BOX-SHADOWS
//=========================================

$shadow-sm: 0 6px 20px -10px rgba(0,0,0,0.6);
$shadow-md: 0 10px 30px -15px rgba(0,0,0,0.6);
$shadow-lg: 0 14px 40px -20px rgba(0,0,0,0.6);

//=========================================
// Z-INDEX
//=========================================

$z-index-top:9999;

$z-index-modal-dropdown:1100;
$z-index-modal:1050;
$z-index-tooltip:1020;
$z-index-panel-close:1000;

$z-index-menu:900;



